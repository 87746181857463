import React, { useState, useEffect, useRef, useContext } from "react";
import WebScanner from "../../../../components/web-scanner/WebScanner";
import UpdatesContainer from "../../../../components/website-updates/UpdatesContainer";
import GenericCard from "../../../../components/card/GenericCard";
import { ThreeCircles } from "react-loader-spinner";
import NoData from "../../../../components/empty/NoData";
import { NoDataAfterScanning } from "../../../../components/empty/NoDataAfterScanning";
import DoughnutChart from "../../../../components/charts/DoughnutChart";
import BarChart from "../../../../components/charts/BarChart";
import moment from "moment";
import { useParams, useOutletContext, useNavigate } from "react-router-dom";
import CurrentStatus from "../../../../components/status/CurrentStatus.js";
import { webUpdatesFilter } from "../../../../util/genericFunctions.js";
import BaseFilter from "../../../../components/filter/BaseFilter";
import BaseTable from "../../../../components/table/BaseTable";
import DecisionToggle from "../../../../components/badge/DecisionToggle";
import TestedPopover from "../../../../components/shared/TestedPopover.js";
import { ReactComponent as RedCross } from "../../../../assets/images/Red-Cross.svg";
import { ReactComponent as GrayCross } from "../../../../assets/images/Grey-Cross.svg";
import {
  circularChartoptions,
} from "../../../../util/chartOptions.js";
import { UseAssetsModal } from "../../../../hooks/useAssetsModal.js";
import { ScannerContext } from "../../../../components/ScannerContext";
import { hostsSecurityTabsConfig } from "../../../../util/tabsConfig.js";
import axios from "../../../../util/axios";
import { formatDate } from "../../../../util/formatDate.js";
import { formatScannerName } from "../../../../helpers/formatScannerName.js";
import { parseFilterString, applyCondition } from "../../../../util/conditions";
import {
  subdomainTakeoverFilter,
  lowEnviornmentFilter,
} from "../../network-security/default-credential/filterOptions.js";
import { barChartOptions } from "../../../../util/chartUtils.js";

const HostsSecurity = () => {
  const navigate = useNavigate();
  const { openModal } = UseAssetsModal();
  const { dropdownItems } = useContext(ScannerContext);
  const { scanningStatus } = useOutletContext();
  const routeParams = useParams();
  const [isDataisPartiallyProcessing, setIsDataisPartiallyProcessing] =
    useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [noDataDoughtnutChart, setNoDataDoughtnutChart] = useState(false);
  const [noDataBarChart, setNoDataBarChart] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeFilters, setActiveFilters] = useState([]);
  const [webUpdatesData, setWebUpdatesData] = useState(null);
  const [updatesRemove, setUpdatesRemove] = useState(null);
  const [isDataInProcess, setIsDataInProcess] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [hostsSecurity, setHostsSecurity] = useState([]);

  const [chartData, setChartData] = useState({
    labels: ["Standard Environment", "Low Environment"],
    datasets: [
      {
        data: [],
        backgroundColor: ["#3DDC97", "#FF6155"],
        hoverOffset: 4,
        borderColor: "transparent",
        borderWidth: 0,
        borderAlign: "center",
      },
    ],
  });

  const [barChartData, setBarChartData] = useState({
    labels: ["Vulnerable", "Not Vulnerable"],
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: ["#FF6155", "#3DDC97"],
        borderColor: ["rgb(255, 99, 132)", "rgb(75, 192, 192)"],
        borderWidth: 1,
      },
    ],
  });

  const handleWebUpdateSort = (name) => {
    setActiveFilters(webUpdatesFilter(name, activeFilters, "5"));
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const getExportedRows = (dataForExport) => {
    const body = dataForExport.flatMap((data) => {
      return [
        [
          data?.host || "-",
          data?.low ? "Yes" : "No",
          data?.takeover ? "Vulnerable" : "Not Vulnerable",
          data?.first_Detected ? formatDate(data?.first_Detected) : "-",
          data?.last_updated ? formatDate(data?.last_updated) : "-",
          data?.change_status === 1
            ? "New"
            : data?.change_status === 2
            ? "Changed"
            : data?.change_status === 0
            ? "Unchanged"
            : data?.change_status === 3
            ? "Removed"
            : "-",
        ],
      ];
    });

    return body;
  };

  const handleFilterChange = (updatedActiveFilters, updatedTabs) => {
    setActiveFilters(updatedActiveFilters);
    setTabs(updatedTabs);
  };

  const removeFilter = (updatedFilters, updatedTabs) => {
    setActiveFilters(updatedFilters);
    setTabs(updatedTabs);
  };

  const updateTabsWithData = (data) => {
    const domains = Array.from(new Set(data.map((item) => item?.domain))).map(
      (domain) => ({
        id: domain,
        name: domain,
        type: "Domain: " + domain,
        key: "1",
        active: false,
      })
    );
    const hostnames = Array.from(new Set(data.map((item) => item?.host))).map(
      (host) => ({
        id: host,
        name: host,
        type: "Hostname: " + host,
        key: "2",
        active: false,
      })
    );

    setTabs(
      hostsSecurityTabsConfig({
        domains,
        hostnames,
        lowEnviornmentFilter,
        subdomainTakeoverFilter,
      })
    );
  };

  const handleChartClick = (name, eventKey, label) => {
    const updatedFilters = webUpdatesFilter(
      name,
      activeFilters,
      eventKey,
      label
    );

    const updatedCertificateExpiration = tabs[4].data.map((item) => ({
      ...item,
      active: updatedFilters.some((filter) => filter.name === item.name),
    }));

    setTabs((prevTabs) => {
      const newTabs = [...prevTabs];
      newTabs[4] = {
        ...newTabs[4],
        data: updatedCertificateExpiration,
      };
      return newTabs;
    });
    setActiveFilters(updatedFilters);
  };

  const handleClickLow = (row) => {
    if (row?.low) {
      navigate(
        `/security-issues/${routeParams?.target_id}?host=${row?.host}&armoryIdParam=arm-lowenv1`
      );
    }
  };

  const handleClickSubdomain = (row) => {
    if (row?.takeover) {
      navigate(
        `/security-issues/${routeParams?.target_id}?host=${row?.host}&armoryIdParam=arm-sub1`
      );
    }
  };

  const columns = [
    {
      Header: "Hostname",
      accessor: (row) => {
        return (
          <span onClick={() => openModal(row?.host)} className="asset-link">
            {row?.host}
          </span>
        );
      },
      isSortable: true,
      key: "host",
    },
    {
      Header: "Low Environment",
      accessor: (row) => (
        <span
          onClick={() => {
            handleClickLow(row);
          }}
        >
          <DecisionToggle
            label={row.low ? "Yes" : "No"}
            isIndication={!row.low}
          />
        </span>
      ),
      isSortable: true,
      key: "low",
    },
    {
      Header: <TestedPopover title="Subdomain Takeover" />,
      accessor: (row) => (
        <span
          onClick={() => {
            handleClickSubdomain(row);
          }}
        >
          <DecisionToggle
            label={
              <div className="d-flex align-items-center">
                {!row.takeover ? (
                  <GrayCross className="me-1" />
                ) : (
                  <RedCross className="me-1" />
                )}
                <div>{row.takeover ? "Vulnerable" : "Not Vulnerable"}</div>
              </div>
            }
            isIndication={!row.takeover}
          />
        </span>
      ),
      isSortable: true,
      key: "takeover",
    },
    {
      Header: "First Detected",
      accessor: (row) => (
        <div>{row?.first_Detected ? formatDate(row?.first_Detected) : ""}</div>
      ),
      isSortable: true,
      key: "first_Detected",
    },
    {
      Header: "Last Updated",
      accessor: (row) => (
        <div>{row?.last_updated ? formatDate(row?.last_updated) : ""}</div>
      ),
      isSortable: true,
      key: "last_updated",
    },
    {
      Header: "Current State",
      key: "current_state",
      isSortable: true,
      accessor: (row) => {
        return (
          <div>
            {row?.change_status != null ? (
              <CurrentStatus
                status={row?.change_status}
                tooltipInfo={row?.changes}
                headerKeys={{
                  host: "Hostname",
                  low: "Low Environment",
                  takeover: "Subdomain Takeover",
                  first_detected: "First Detected",
                  last_updated: "Last Updated",
                  change_status: "Current State",
                }}
              />
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];

  const fetchHostsSecurity = async () => {
    try {
      const { data } = await axios.get(
        `scans/${routeParams?.target_id}/hostsecurity`
      );
      if (data?.groups) {
        setWebUpdatesData(data?.groups?.changes);
        setUpdatesRemove(data?.groups?.removed);
        setChartData({
          labels: ["Standard Environment", "Low Environment"],
          datasets: [
            {
              data: [
                data?.groups?.hostByEnvironment?.standard || 0,
                data?.groups?.hostByEnvironment?.low || 0,
              ],
              backgroundColor: ["#3DDC97", "#FF6155"],
              hoverOffset: 4,
              borderColor: "transparent",
              borderWidth: 0,
              borderAlign: "center",
            },
          ],
        });
        setBarChartData({
          labels: ["Vulnerable", "Not Vulnerable"],
          datasets: [
            {
              label: ["Vulnerable", "Not Vulnerable"],
              data: [
                data?.groups?.subdomainTakeoverAttack?.vulnerable || 0,
                data?.groups?.subdomainTakeoverAttack?.notVulnerable || 0,
              ],
              backgroundColor: ["#FF6155", "#3DDC97"],
              borderColor: ["rgb(255, 99, 132)", "rgb(75, 192, 192)"],
              borderWidth: 1,
              maxBarThickness: 70,
              borderRadius: {
                topLeft: 5,
                topRight: 5,
              },
            },
          ],
        });
      }
      if (data?.results?.length > 0) {
        updateTabsWithData(data?.results);
        setHostsSecurity(data?.results);
      }
      if (
        scanningStatus === -1 ||
        scanningStatus === 3 ||
        scanningStatus === 4 ||
        scanningStatus === 1 ||
        scanningStatus === 2
      ) {
        if (
          data?.groups?.subdomainTakeoverAttack?.notVulnerable ||
          data?.groups?.subdomainTakeoverAttack?.vulnerable
        ) {
          setNoDataBarChart(false);
        } else {
          setNoDataBarChart(true);
        }
        if (
          data?.groups?.hostByEnvironment?.low ||
          data?.groups?.hostByEnvironment?.standard
        ) {
          setNoDataDoughtnutChart(false);
        } else {
          setNoDataDoughtnutChart(true);
        }
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const fetchIntervalRef = useRef();

  useEffect(() => {
    clearInterval(fetchIntervalRef.current);

    if (scanningStatus === 0) {
      setIsLoading(false);
      setIsDataInProcess(true);
    } else {
      setIsDataInProcess(false);
      if (
        scanningStatus === 3 ||
        scanningStatus === -1 ||
        scanningStatus === 4
      ) {
        setIsDataisPartiallyProcessing(false);
        setIsLoading(true);
        fetchHostsSecurity();
      } else if (scanningStatus === 2 || scanningStatus === 1) {
        setIsDataisPartiallyProcessing(true);
        setIsLoading(true);
        fetchHostsSecurity();
        fetchIntervalRef.current = setInterval(fetchHostsSecurity, 5000);
      }
    }
    return () => {
      clearInterval(fetchIntervalRef.current);
    };
  }, [scanningStatus, routeParams?.target_id]);

  const statusMap = { new: 1, changed: 2, unchanged: 0 };

  const groupedFilters = activeFilters.reduce((acc, filter) => {
    if (!acc[filter.eventKey]) {
      acc[filter.eventKey] = [];
    }
    acc[filter.eventKey].push(filter);
    return acc;
  }, {});

  const filteredHostsSecurity = hostsSecurity.filter((item) => {
    const matchesFilters = Object.keys(groupedFilters).every((eventKey) => {
      return groupedFilters[eventKey].some((filter) => {
        const lowerCaseFilter = filter.name.toLowerCase();
        if (eventKey === "1") {
          return item.domain && item.domain.toLowerCase() === lowerCaseFilter;
        } else if (eventKey === "2") {
          return item.host && item.host.toLowerCase() === lowerCaseFilter;
        } else if (eventKey === "3") {
          return (
            (lowerCaseFilter === "no" && !item.low) ||
            (lowerCaseFilter === "yes" && item.low)
          );
        } else if (eventKey === "4") {
          return (
            (lowerCaseFilter === "not vulnerable" && !item.takeover) ||
            (lowerCaseFilter === "vulnerable" && item.takeover)
          );
        } else if (eventKey === "5") {
          return item?.change_status === statusMap[filter.name.toLowerCase()];
        } else if (eventKey === "advanced-filter") {
          const parsedFilters = parseFilterString(filter.name);
          return parsedFilters.every((ol) => {
            const { column, condition, value } = ol;
            switch (column) {
              case "domain":
                return applyCondition(
                  item.domain?.toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              case "host":
                return applyCondition(
                  item.host?.toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              case "low":
                return (
                  (value?.toLowerCase() === "no" && !item.low) ||
                  (value?.toLowerCase() === "yes" && item.low)
                );
              case "takeover":
                return (
                  (value?.toLowerCase() === "not vulnerable" &&
                    !item.takeover) ||
                  (value?.toLowerCase() === "vulnerable" && item.takeover)
                );
              case "current_state":
                return applyCondition(
                  item?.change_status,
                  condition,
                  statusMap[value.toLowerCase()]
                );

              default:
                return false;
            }
          });
        }
        return false;
      });
    });

    const matchesSearch =
      searchValue === "" ||
      (item.host &&
        item.host.toLowerCase().includes(searchValue.toLowerCase()));
    return matchesFilters && matchesSearch;
  });

  return (
    <>
      {isLoading ? (
        <div className="content-loader">
          <ThreeCircles
            visible={true}
            height="60"
            width="60"
            color="#ffff"
            ariaLabel="three-circles-loading"
            wrapperClass=""
          />
        </div>
      ) : (
        <div className="main_container_style">
          <div className="pb-4 row top-container-main web-updates-container">
            <div className="col-12 col-lg-6 h-100 web-updates-col-7">
              <div className="row gap-4 top-container-main-row">
                <div className="col-12 h-50">
                  <GenericCard
                    children={
                      isDataInProcess ? (
                        <NoData />
                      ) : isDataisPartiallyProcessing &&
                        noDataDoughtnutChart ? (
                        <NoData />
                      ) : noDataDoughtnutChart ? (
                        <NoDataAfterScanning />
                      ) : (
                        <DoughnutChart
                          data={chartData}
                          options={circularChartoptions}
                          onHandleClick={(name) => {
                            handleChartClick(
                              name == "Standard Environment" ? "No" : "Yes",
                              "3",
                              "Low Environment"
                            );
                          }}
                        />
                      )
                    }
                    title={"Hostnames by Environment"}
                    isCentered={false}
                  />
                </div>
                <div className="col-12 h-50">
                  <div className="row h-100">
                    <div className="col-5">
                      <GenericCard
                        children={
                          <UpdatesContainer
                            data={webUpdatesData}
                            onSort={handleWebUpdateSort}
                            dataInProcess={
                              isDataInProcess || isDataisPartiallyProcessing
                            }
                            updatesRemove={updatesRemove} 
                          />
                        }
                        title={"Updates"}
                      />
                    </div>
                    <div className="col-7">
                      <GenericCard
                        children={
                          isDataInProcess ? (
                            <NoData />
                          ) : isDataisPartiallyProcessing && noDataBarChart ? (
                            <NoData />
                          ) : noDataBarChart ? (
                            <NoDataAfterScanning />
                          ) : (
                            <BarChart
                              barData={barChartData}
                              optionsData={barChartOptions}
                              onBarClick={(name) =>
                                handleChartClick(
                                  name,
                                  "4",
                                  "Subdomain Takeover"
                                )
                              }
                              dynamicLabels={["Vulnerable", "Not Vulnerable"]}
                              isChartLabelsOnTop
                            />
                          )
                        }
                        title={"Subdomain Takeover Attack"}
                        subtitle={"View Issues"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 h-100 web-updates-col-5">
              <WebScanner title={"SSH Authentication Security Scanner"} />
            </div>
          </div>
          <div className="px-10 pb-3">
            <div className="py-1">
              <BaseFilter
                className="mt-3 mb-3"
                tabs={tabs}
                totalRecords={filteredHostsSecurity.length}
                onFilterChange={handleFilterChange}
                activeFilters={activeFilters}
                removeFilter={removeFilter}
                isDatainProcess={isDataInProcess || isDataisPartiallyProcessing}
                searchValue={searchValue}
                onSearchChange={handleSearchChange}
                isSearch={true}
                tableData={filteredHostsSecurity}
                exportTitle={`HostsSecurity_${formatScannerName(
                  dropdownItems.filter((item) => {
                    return item.target_uuid === routeParams?.target_id;
                  })[0]?.title
                )}-${moment().format("DDMMMYYYY").toUpperCase()}`}
                exportHeader={[
                  "Hostname",
                  "Low Environment",
                  "Subdomain Takeover",
                  "First Detected",
                  "Last Updated",
                  "Current State",
                ]}
                exportRows={getExportedRows(filteredHostsSecurity)}
              />
            </div>
            <div>
              <BaseTable
                className="mt-3 mb-3"
                columns={columns}
                data={filteredHostsSecurity}
                selectable={true}
                showCheckboxes={false}
                isDatainProcess={isDataInProcess || isDataisPartiallyProcessing}
                loading={isLoading}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HostsSecurity;
