import React, { useState, useEffect, useRef, useContext } from "react";
import WebScanner from "../../../../components/web-scanner/WebScanner";
import UpdatesContainer from "../../../../components/website-updates/UpdatesContainer";
import BaseTable from "../../../../components/table/BaseTable";
import BaseFilter from "../../../../components/filter/BaseFilter";
import DoughnutChart from "../../../../components/charts/DoughnutChart";
import BarChart from "../../../../components/charts/BarChart";
import axios from "../../../../util/axios";
import { useParams, useOutletContext } from "react-router-dom";
import { ThreeCircles } from "react-loader-spinner";
import GenericCard from "../../../../components/card/GenericCard";
import { Badge } from "react-bootstrap";
import DecisionToggle from "../../../../components/badge/DecisionToggle.js";
import NoData from "../../../../components/empty/NoData.js";
import { Popover } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { NoDataAfterScanning } from "../../../../components/empty/NoDataAfterScanning.js";
import { ScannerContext } from "../../../../components/ScannerContext";
import { formatScannerName } from "../../../../helpers/formatScannerName.js";
import moment from "moment";

const LoginPages = () => {
  const { dropdownItems } = useContext(ScannerContext);
  const { scanningStatus } = useOutletContext();
  const [searchValue, setSearchValue] = useState("");
  const [isDatainProcess, setIsDataInProcess] = useState(false);
  const [isDataisPartiallyProcessing, setIsDataisPartiallyProcessing] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [activeFilters, setActiveFilters] = useState([]);
  const [noDataDoughnutChart, setNoDataDoughtnutChart] = useState(false);
  const [noDataBarChart, setNoDataBarChart] = useState(false);
  const [pages, setPages] = useState([]);
  const [doughnutChartType, setDoughnutChartType] = useState([]);
  const [barChartType, setBarChartType] = useState([]);
  const routeParams = useParams();
  const [hoveredRowId, setHoveredRowId] = useState(null);
  const [showPopover, setShowPopover] = useState(false);
  const rowRefs = useRef({});

  const [tabs, setTabs] = useState([
    {
      eventKey: "1",
      title: "Login Page",
      contentTitle: "Login Page",
      data: [],
    },
    {
      eventKey: "2",
      title: "Brute Force",
      contentTitle: "Brute Force",
      data: [],
    },
    {
      eventKey: "3",
      title: "Captcha",
      contentTitle: "Captcha",
      data: [],
    },
  ]);

  const columns = [
    {
      isSortable: true,
      Header: "Login Page",
      width: 5,
      key: "page",
      accessor: (row) => (
        <div>
          <a
            className=""
            href={row.page}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="align-items-center">
              <span className="hover-none text-link-http ">{row.page}</span>
            </div>
          </a>
        </div>
      ),
    },
    {
      Header: "Fields",
      isSortable: true,
      key: "fields",
      accessor: (row) => (
        <div className="d-flex flex-wrap">
          {row.fields.map((field, index) => (
            <div key={index} className="me-1 my-1">
              <Badge className="secondary-badges rounded">{field}</Badge>
            </div>
          ))}
        </div>
      ),
    },

    {
      Header: "Captcha",
      isSortable: true,
      key: "has_captcha",
      accessor: (row) => (
        <div className="d-flex">
          <span>{row.has_captcha === 1 ? "Yes" : "No"}</span>
        </div>
      ),
    },
    {
      Header: "User Enumeration",
      isSortable: true,
      key: "has_user_enumeration",
      accessor: (row) => (
        <div>
          <DecisionToggle
            label={row.has_user_enumeration === 1 ? "Yes" : "No"}
            isIndication={row.has_user_enumeration === 1 ? false : true}
          />
        </div>
      ),
    },
    {
      Header: "WAF",

      isSortable: true,
      key: "has_waf",

      accessor: (row) => (
        <div>
          <DecisionToggle
            label={row.has_waf === 1 ? "Yes" : "No"}
            isIndication={row.has_waf === 1 ? true : false}
          />
        </div>
      ),
    },

    {
      Header: "Brute Force",
      isSortable: true,
      key: "has_brute_force",
      accessor: (row) => {
        const isSusceptible = row.has_brute_force === 2;
        const rowId = row.id;
        const popover = (
          <Popover
            id={`popover-bruteforce-${row.id}`}
            className="custom-popover-arrow"
          >
            <Popover.Body className="comming-tool-body ">
              <label className="text-black">
                The brute force attack did not result in successful unauthorized
                access, but the login page lacked visible countermeasures. The
                absence of visible countermeasures does not definitively confirm
                the vulnerability, as there may be backend protections not
                evident during testing.
              </label>
            </Popover.Body>
          </Popover>
        );

        return (
          <OverlayTrigger
            placement="top"
            overlay={popover}
            show={isSusceptible && hoveredRowId === rowId && showPopover}
            trigger="click"
          >
            <div
              onMouseEnter={() => {
                document.body.style.overflowY = "hidden";
                setHoveredRowId(rowId);
                setShowPopover(true);
              }}
              onMouseLeave={() => {
                setHoveredRowId(null);
                setShowPopover(false);
              }}
              ref={(el) => (rowRefs.current[rowId] = el)}
              className="w-fit-content"
            >
              <DecisionToggle
                label={
                  row.has_brute_force === 1
                    ? "Yes"
                    : row.has_brute_force === 0
                    ? "No"
                    : "Susceptible"
                }
                isIndication={
                  row.has_brute_force === 1
                    ? false
                    : row.has_brute_force === 0
                    ? true
                    : "yellow"
                }
              />
            </div>
          </OverlayTrigger>
        );
      },
    },
  ];

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const updateTabsWithData = (pagesData) => {
    const pages = Array.from(new Set(pagesData.map((page) => page.page))).map(
      (page, index) => ({
        id: index,
        name: page,
        type: "Login Page: " + page,
        key: "1",
        active: false,
      })
    );

    const bruteForces = [
      {
        id: 0,
        name: "Yes",
        type: "Brute Force: Yes",
        key: "2",

        active: false,
      },
      {
        id: 1,
        name: "No",
        type: "Brute Force: No",
        key: "2",
        active: false,
      },
      {
        id: 2,
        name: "optional",
        type: "Brute Force: optional",
        key: "2",
        active: false,
      },
    ];
    const wafs = [
      {
        id: 0,
        name: "Yes",
        type: "WAF: Yes",
        key: "3",
        active: false,
      },
      {
        id: 1,
        name: "No",
        type: "WAF: No",
        key: "3",
        active: false,
      },
    ];
    const captchas = [
      {
        id: 0,
        name: "Yes",
        type: "Captcha: Yes",
        key: "4",
        active: false,
      },
      {
        id: 1,
        name: "No",
        type: "Captcha: No",
        key: "4",
        active: false,
      },
    ];
    const userEnumerations = [
      {
        id: 0,
        name: "Yes",
        type: "User Enumeration: Yes",
        key: "5",
        active: false,
      },
      {
        id: 1,
        name: "No",
        type: "User Enumeration: No",
        key: "5",
        active: false,
      },
    ];

    const fields = Array.from(
      new Set(pagesData.map((page) => page.fields).flat())
    ).map((field, index) => ({
      id: index,
      name: field,
      key: "6",
      active: false,
    }));
    setTabs([
      {
        eventKey: "1",
        title: "Login Page",
        contentTitle: "Login Page",
        data: Array.from(pages),
      },
      {
        eventKey: "4",
        title: "Captcha",
        contentTitle: "Captcha",
        data: captchas,
      },
      {
        eventKey: "5",
        title: "User Enumeration",
        contentTitle: "User Enumeration",
        data: userEnumerations,
      },
      {
        eventKey: "3",
        title: "WAF",
        contentTitle: "WAF",
        data: wafs,
      },
      {
        eventKey: "2",
        title: "Brute Force",
        contentTitle: "Brute Force",
        data: bruteForces,
      },
    ]);
  };

  // doughnut Chart Data
  const doughnutChartData = {
    labels: Object.keys(doughnutChartType),
    datasets: [
      {
        label: "Login",
        data: Object.values(doughnutChartType),
        backgroundColor: ["#2AA16E", "#FF6155"],
        hoverOffset: 4,
        borderColor: "transparent",
        borderWidth: 0,
        width: 50,
        borderAlign: "center",
      },
    ],
  };

  const doughnutChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "70%",
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };
  const colors = ["#B694FF", "#398FFE"];
  const datasets = Object.entries(barChartType)?.map(
    ([label, value], index) => ({
      label: label == "enum" ? "User Enumeration" : "Brute Force",
      data: [value],
      backgroundColor: colors[index % colors.length],
      borderWidth: 1,
      maxBarThickness: 70,
      borderRadius: {
        topLeft: 5,
        topRight: 5,
      },
    })
  );

  const barChartData = {
    labels: [""],
    datasets: datasets,
  };

  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          color: "white",
          usePointStyle: true,
          pointStyle: "rectRounded",
        },
      },
      tooltip: {
        backgroundColor: "white",
        titleColor: "black",
        bodyColor: "black",
        padding: 20, // Add padding around the tooltip content
        yPadding: 90,
        callbacks: {
          label: function (context) {
            const label = context.dataset.label || "";
            const value = context.raw;
            return `${label}: ${value}`;
          },
        },
        // Set tooltip alignment and anchor
        yAlign: "bottom",
        xAlign: "center",
      },
      datalabels: {
        color: "white",
        anchor: "end",
        align: "top",
        formatter: (value, context) => {
          return value;
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: "white",
        },
        border: { dash: [4, 4] }, // for the grid lines

        grid: {
          color: "rgba(255, 255, 255, 0.1)",
          borderDash: [10, 10], // Dashed lines
        },
      },
      x: {
        ticks: {
          color: "white",
        },
        display: false,
        grid: {
          color: "rgba(255, 255, 255, 0.1)",
          drawOnChartArea: false, // only draw the border line on the bottom
        },
      },
    },
    layout: {
      padding: {
        top: 30,
        bottom: 10, // Adjust padding to your liking
      },
    },
  };

  // table
  const sampleData = [
    { id: 1, type: "upload" },
    { id: 2, type: "upload" },
    { id: 3, type: "removal" },
    { id: 4, type: "removal" },
    { id: 5, type: "upload" },
  ];

  const fetchEasm = async () => {
    try {
      const { data } = await axios.get(
        `scans/${routeParams.target_id}/login-pages`
      );
      setPages(data.pages);
      updateTabsWithData(data.pages);
      if (
        data?.pages?.length == 0 &&
        (scanningStatus === 2 || scanningStatus === 1)
      ) {
        setIsDataInProcess(true);
      } else {
        setIsDataInProcess(false);
      }
      const secureData = data.groups?.secure;
      if (secureData) {
        const transformedData = {
          Secured: secureData.secure,
          Unsecured: secureData.unsecure,
        };
        setDoughnutChartType(transformedData);
      }

      setBarChartType(data.groups?.vulnerable);
      if (scanningStatus === -1 || scanningStatus === 3) {
        if (
          Object.values(data?.group?.secure)?.every((value) => value === 0) ||
          Object.values(data?.group?.secure)?.length === 0
        ) {
          setNoDataDoughtnutChart(true);
        } else {
          setNoDataDoughtnutChart(false);
        }
        if (
          Object.values(data?.group?.vulnerable)?.every(
            (value) => value === 0
          ) ||
          Object.values(data?.group?.vulnerable)?.length === 0
        ) {
          setNoDataBarChart(true);
        } else {
          setNoDataBarChart(false);
        }
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const fetchIntervalRef = useRef();
  useEffect(() => {
    clearInterval(fetchIntervalRef.current);

    if (scanningStatus === 0) {
      setIsLoading(false);
      setIsDataInProcess(true);
    } else {
      setIsDataInProcess(false);
      if (scanningStatus === 3 || scanningStatus === -1) {
        setIsDataisPartiallyProcessing(false);
        setIsLoading(true);
        fetchEasm();
      } else if (scanningStatus === 2 || scanningStatus === 1) {
        setIsDataisPartiallyProcessing(true);
        setIsLoading(true);
        fetchEasm();
        fetchIntervalRef.current = setInterval(fetchEasm, 5000);
      }
    }
    return () => {
      clearInterval(fetchIntervalRef.current);
    };
  }, [scanningStatus, routeParams?.target_id]);

  const handleFilterChange = (updatedActiveFilters, updatedTabs) => {
    setActiveFilters(updatedActiveFilters);
    setTabs(updatedTabs);
  };

  const removeFilter = (updatedFilters, updatedTabs) => {
    setActiveFilters(updatedFilters);
    setTabs(updatedTabs);
  };
  const groupedFilters = activeFilters.reduce((acc, filter) => {
    if (!acc[filter.eventKey]) {
      acc[filter.eventKey] = [];
    }
    acc[filter.eventKey].push(filter);
    return acc;
  }, {});

  const filteredPags = pages.filter((page) => {
    // Check if item matches all groups of filters (intersection)
    const matchesFilters = Object.keys(groupedFilters).every((eventKey) => {
      // Check if item matches at least one filter in the current group (union)
      return groupedFilters[eventKey].some((filter) => {
        const lowerCaseFilter = filter.name.toLowerCase();
        if (eventKey === "1") {
          return page.page.toLowerCase() === lowerCaseFilter;
        } else if (eventKey === "2") {
          return (
            (lowerCaseFilter === "optional" && page.has_brute_force === 2) ||
            (lowerCaseFilter === "no" && page.has_brute_force === 0) ||
            (lowerCaseFilter === "yes" && page.has_brute_force === 1)
          );
        } else if (eventKey === "3") {
          return (
            (lowerCaseFilter === "no" && page.has_waf === 0) ||
            (lowerCaseFilter === "yes" && page.has_waf === 1)
          );
        } else if (eventKey === "4") {
          return (
            (lowerCaseFilter === "no" && page.has_captcha === 0) ||
            (lowerCaseFilter === "yes" && page.has_captcha === 1)
          );
        } else if (eventKey === "5") {
          return (
            (lowerCaseFilter === "no" && page.has_user_enumeration === 0) ||
            (lowerCaseFilter === "yes" && page.has_user_enumeration === 1)
          );
        }
        return false;
      });
    });

    const matchesSearch =
      searchValue === "" ||
      page.page.toLowerCase().includes(searchValue.toLowerCase());

    return matchesFilters && matchesSearch;
  });

  const SortingTemplate = (data) => {
    console.log("data", data);
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="content-loader">
          <ThreeCircles
            visible={true}
            height="60"
            width="60"
            color="#ffff"
            ariaLabel="three-circles-loading"
            wrapperClass=""
          />
        </div>
      ) : (
        <div className="main_container_style">
          <div className="pb-4 row top-container-main web-updates-container">
            <div className="col-12 col-lg-6 h-100 web-updates-col-7">
              <div className="row gap-4 top-container-main-row">
                <div className="col-12 h-50">
                  <GenericCard
                    children={
                      noDataDoughnutChart ? (
                        <NoDataAfterScanning />
                      ) : isDatainProcess ? (
                        <NoData />
                      ) : (
                        <DoughnutChart
                          data={doughnutChartData}
                          options={doughnutChartOptions}
                        />
                      )
                    }
                    title={"Secure vs Unsecured Login Pages"}
                  />
                </div>
                <div className="col-12 h-50">
                  <div className="row h-100">
                    <div className="col-5">
                      <GenericCard
                        children={<UpdatesContainer data={sampleData} />}
                        title={"Updates"}
                      />
                    </div>
                    <div className="col-7">
                      <GenericCard
                        children={
                          noDataBarChart ? (
                            <NoDataAfterScanning />
                          ) : isDatainProcess ? (
                            <NoData />
                          ) : (
                            <BarChart
                              barData={barChartData}
                              optionsData={barChartOptions}
                              isData={true}
                              isChartLabelsOnTop
                              marginBottom={"-30px"}
                            />
                          )
                        }
                        title={"Login Pages Vulnerable"}
                        subtitle={"View Issues"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 h-100 web-updates-col-5">
              <WebScanner title={"Login Pages Scanner"} />
            </div>
          </div>

          <div className=" pb-5">
            {/* <div className="table-container">
              <div className="table-left">Results</div>
              <div>
                <BaseInput
                  type="text"
                  placeholder="Search..."
                  className="search-input"
                  icon={Search}
                  value={searchValue}
                  onChange={handleSearchChange}
                />
              </div>
            </div> */}
            <div>
              <BaseFilter
                tabs={tabs}
                onFilterChange={handleFilterChange}
                activeFilters={activeFilters}
                removeFilter={removeFilter}
                totalRecords={filteredPags.length}
                exportHeader={[
                  "Login Page",
                  "Field",
                  "Captcha",
                  "User Enumeration",
                  "WAF",
                  "Brute Force",
                ]}
                exportTitle={`LoginPages_${formatScannerName(
                  dropdownItems.filter((item) => {
                    return item.target_uuid === routeParams?.target_id;
                  })[0]?.title
                )}-${moment().format("DDMMMYYYY").toUpperCase()}`}
                exportRows={filteredPags.map((page) => [
                  page.page,
                  `"${page.fields.join(", ")}"`,
                  page.has_captcha ? "Yes" : "No",
                  page.has_user_enumeration ? "Yes" : "No",
                  page.has_waf ? "Yes" : "No",
                  page.has_brute_force === 0
                    ? "No"
                    : page.has_brute_force === 1
                    ? "Yes"
                    : "Optional",
                ])}
                isDatainProcess={isDatainProcess || isDataisPartiallyProcessing}
                searchValue={searchValue}
                onSearchChange={handleSearchChange}
                isSearch={true}
              />
            </div>
            <div>
              <BaseTable
                className="mt-3 mb-3"
                columns={columns}
                data={filteredPags}
                selectable={true}
                showCheckboxes={false}
                action={false}
                loading={isLoading}
                SortingTemplate={SortingTemplate}
                isDatainProcess={isDatainProcess}
                isTemplateSort={true}
              />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default LoginPages;
