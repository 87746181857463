 export const generateRemovedMessages = (updatesRemove) => {
    const messages = [];
    if (updatesRemove?.removedPorts) {
      updatesRemove.removedPorts.forEach((item) => {
        const [ip, port] = item.split(":");
        messages.push(`Port ${port} On IP ${ip} Has Been Removed`);
      });
    }
    if (updatesRemove?.removedIps) {
      updatesRemove.removedIps.forEach((ip) => {
        messages.push(`IP ${ip} Has Been Removed`);
      });
    }
    if (updatesRemove?.inactive) {
      updatesRemove.inactive.forEach((ip) => {
        messages.push(`IP ${ip} Has Been Inactive`);
      });
    }
    if (updatesRemove?.removedHost) {
      updatesRemove.removedHost.forEach((host) => {
        messages.push(`Host ${host} Has Been Removed`);
      });
    }
    if (updatesRemove?.removedUrl) {
      updatesRemove.removedUrl.forEach((item) => {
        console.log("item-item", item.url);
         if (item?.category) {
          const hostname = new URL(item.url).hostname; 
          messages.push(
            `URL On Hostname ${hostname} Under ${item.category} Category Has Been Removed`
          );
        }  else {
          const host = item.url.split('/login')[0];
          messages.push(`Login Page On ${host} Has Been Removed`);
        }
      });
    }
    
    return messages;
  };