import React, { useState, useEffect, useRef } from "react";
import HighlightsRisk from "../../components/highlights-risk/HighlightsRisk";
import OptionsSection from "../../components/options-section/OptionsSection";
import { ReactComponent as DataProcess } from "../../assets/images/data-process.svg";
import { ReactComponent as NoIssue } from "../../assets/images/completed.svg";
import { ReactComponent as Folder } from "../../assets/images/folder.svg";
import axios from "../../util/axios";
import { ThreeCircles } from "react-loader-spinner";
import BreadCrumb from "../../components/breadcrumbs/BreadCrumb";
import { useParams, useLocation } from "react-router-dom";
import GenericCard from "../../components/card/GenericCard.js";
import IssueStatusChart from "../../components/charts/IssueStatusChart";
import { Chart } from "chart.js";
import { useMemo } from "react";
import GroupSwitch from "../../components/cves/Switch";
import CveList from "./CveList";
import {
  webUpdatesFilter,
  webDataFillter,
} from "../../util/genericFunctions.js";
import GenericActionModal from "./GenericActionModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  impacts,
  Exploitabilities,
  CVSS,
  EPSS,
  scores,
  hasCveFilterOptions,
  serviceMap,
  openColorList,
  historycolorList,
  criticalityFilterList,
  cisaKevFilterList,
  armoryTestFilterList,
  issueStateFilterList,
  newTagList,
  warRoomFilterList,
  hasCVEFilterList,
  issueStatusHistoryFilterList,
} from "./filterOptions.js";
import { parseFilterString, applyCondition } from "../../util/conditions";
import { securityIssuesTabsConfig } from "../../util/tabsConfig.js";

const OpenIssues = ({ type, updateScanningDetails, activeTab }) => {
  const timeoutRef = useRef(null);
  const routeParams = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ipParam = searchParams.get("ip");
  const scannerParam = searchParams.get("scanner");
  const armoryIdParam = searchParams.get("armoryIdParam");
  const domainParam = searchParams.get("domain");
  const hostParam = searchParams.get("host");
  const riskParam = searchParams.get("risk_score");
  const portParam = searchParams.get("port");
  const issueParam = searchParams.get("issue");
  const issueStatus = searchParams.get("issueStatus");
  const isNew = searchParams.get("is_new");
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [cves, setCves] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  const [isSortBy, setIsSortBy] = useState("rhl");
  const [isFinished, setIsFinished] = useState(false);
  const [selectedSortValue, setSelectedSortValue] = useState(
    "Risk Score High to Low"
  );
  const [selectedRiskLevel, setSelectedRiskLevel] = useState(null);
  const [originalCves, setOriginalCves] = useState([]);
  const [separatedCves, setSeparatedCves] = useState([]);
  const [selectedIssueStatus, setSelectedIssueStatus] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [clearRowsTrigger, setClearRowsTrigger] = useState(0);
  const [modalData, setModalData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isBulkSelectedAll, SetIsBulkSelectedAll] = useState(false);
  const [selectedCves, setSelectedCves] = useState([]);
  const [checkedState, setCheckedState] = useState({});
  const [totalNewIssues, setTotalNewIssues] = useState(0);
  const user = JSON.parse(localStorage.getItem("user"));
  const isViewUser = user?.member_level === 0;

  const actions =
    type === "open"
      ? [
          { id: 1, name: "Solve" },
          { id: 2, name: "False-Positive" },
          { id: 3, name: "Acknowledge Risk" },
        ]
      : [{ id: 4, name: "Restore" }];

  const [doughnutChartType, setDoughnutChartType] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: openColorList,
        hoverBackgroundColor: openColorList,
        borderColor: "transparent",
        borderWidth: 0,
      },
    ],
  });

  const centerTextPlugin = {
    id: "centerText",
    afterDraw: function (chart) {
      const ctx = chart.ctx;
      const width = chart.width;
      const height = chart.height;
      const totalIssuesStatus =
        chart.config.options.plugins.centerText?.totalIssuesStatus;

      if (totalIssuesStatus !== undefined) {
        ctx.restore();
        const fontSize = (height / 114).toFixed(2);
        ctx.font = `${fontSize}em Kodchasan`;
        ctx.textBaseline = "middle";
        ctx.fillStyle = "#F5F5F5";

        const text = `${totalIssuesStatus}`; // Total value to display
        const textX = Math.round((width - ctx.measureText(text).width) / 2);
        const textY = height / 2;

        ctx.fillText(text, textX, textY);
        ctx.save();
      }
    },
  };

  Chart.register(centerTextPlugin);
  const totalIssuesStatus = doughnutChartType.datasets[0].data.reduce(
    (acc, val) => acc + val,
    0
  );
  const doughnutChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "70%",
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "white",
        titleColor: "black",
        bodyColor: "black",
        callbacks: {
          title: function () {
            return ""; // Remove the top title
          },
          label: function (context) {
            const label = context.label || "";
            const value = context.raw;
            return `${label}: ${value}`;
          },
        },
        yAlign: "center",
        xAlign: "right",
      },
      title: {
        display: false,
      },
      centerText: {
        totalIssuesStatus: totalIssuesStatus,
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
        borderColor: "transparent",
      },
    },
    onHover: (event, elements) => {
      event.native.target.style.cursor = elements.length
        ? "pointer"
        : "default";
    },
  };
  const [tabs, setTabs] = useState([]);
  const [assets, setAssets] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [collapsedGroups, setCollapsedGroups] = useState({});

  const sortData = (allCves, sortBy) => {
    switch (sortBy) {
      case "chl":
        return allCves.sort((a, b) => {
          const cvssA = a.cvss === "-" ? -Infinity : parseFloat(a.cvss);
          const cvssB = b.cvss === "-" ? -Infinity : parseFloat(b.cvss);
          return cvssB - cvssA;
        });
      case "clh":
        return allCves.sort((a, b) => {
          const cvssA = a.cvss === "-" ? -Infinity : parseFloat(a.cvss);
          const cvssB = b.cvss === "-" ? -Infinity : parseFloat(b.cvss);
          return cvssA - cvssB;
        });
      case "rhl":
        return allCves.sort((a, b) => b.risk_score - a.risk_score);
      case "rlh":
        return allCves.sort((a, b) => a.risk_score - b.risk_score);
      case "ihl":
        return allCves.sort((a, b) => b.impact - a.impact);
      case "ilh":
        return allCves.sort((a, b) => a.impact - b.impact);
      case "ehl":
        return allCves.sort((a, b) => b.exploitability - a.exploitability);
      case "elh":
        return allCves.sort((a, b) => a.exploitability - b.exploitability);
      case "ephl":
        return allCves.sort((a, b) => {
          const epssA = a.epss === "-" ? -Infinity : parseFloat(a.epss);
          const epssB = b.epss === "-" ? -Infinity : parseFloat(b.epss);
          return epssB - epssA;
        });
      case "eplh":
        return allCves.sort((a, b) => {
          const epssA = a.epss === "-" ? -Infinity : parseFloat(a.epss);
          const epssB = b.epss === "-" ? -Infinity : parseFloat(b.epss);
          return epssA - epssB;
        });
      default:
        return allCves;
    }
  };

  const handleSortChange = (e) => {
    setIsSortBy(e.value);
    setSelectedSortValue(e.label);
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleFilterChange = (updatedActiveFilters, updatedTabs) => {
    setActiveFilters(updatedActiveFilters);
    setTabs(updatedTabs);
  };

  const removeFilter = (updatedFilters, updatedTabs) => {
    setActiveFilters(updatedFilters);
    setTabs(updatedTabs);
  };

  const firstSeenFilterOptions = (key, name) => {
    return [
      {
        id: 0,
        name: "Last 24 Hours",
        type: `${name}: Last 24 Hours`,
        key: key,
        active: false,
      },
      {
        id: 1,
        name: "Last 7 Days",
        type: `${name}: Last 7 Days`,
        key: key,
        active: false,
      },
      {
        id: 2,
        name: "Last 30 Days",
        type: `${name}: Last 30 Days`,
        key: key,
        active: false,
      },
    ];
  };

  const updateTabsWithData = (cveData) => {
    const uniqueDomains = Array.from(
      new Set(
        cveData
          .flatMap((cve) => cve.assets?.map((asset) => asset.domain) || [])
          .filter((domain) => domain)
      )
    ).map((domain) => ({
      id: domain,
      name: `${domain}`,
      type: `Domain: ${domain}`,
      key: "10",
      active: false,
    }));

    const uniqueHost = Array.from(
      new Set(
        cveData
          .flatMap((cve) => cve.assets?.map((asset) => asset.host) || [])
          .filter((host) => host)
      )
    ).map((host) => ({
      id: host,
      name: `${host}`,
      type: `Hostname: ${host}`,
      key: "11",
      active: false,
    }));

    const uniqueIpLists = Array.from(
      new Set(
        cveData
          .flatMap((cve) => cve.assets?.map((asset) => asset.ip) || [])
          .filter((ip) => ip)
      )
    ).map((ip) => ({
      id: ip,
      name: `${ip}`,
      type: `IP: ${ip}`,
      key: "12",
      active: false,
    }));

    const titles = Array.from(
      new Set(cveData.map((cve) => cve.issue_name))
    ).map((title) => ({
      id: title,
      name: title,
      type: "Title: " + title,
      key: "13",
      active: false,
    }));
    const daysOpen = Array.from(
      new Set(
        cveData
          .flatMap((cve) => cve.assets?.map((asset) => asset.days_open) || [])
          .filter((days_open) => days_open)
      )
    ).map((el) => ({
      id: el,
      name: `${el}`,
      type: "Days Open: " + `${el}`,
      key: "16",
      active: false,
    }));
    const scanners = Array.from(
      new Set(
        cveData
          .map((scan) =>
            scan.service_id == 2 || scan.service_id == 18 ? 25 : scan.service_id
          )
          .filter((id) => id !== null && id !== undefined)
      )
    )
      .map((scan) => {
        const service = serviceMap.find((s) => s.id === scan);
        if (service) {
          return {
            id: scan,
            name: service.serviceName,
            type: `Scanner: ${service.serviceName}`,
            key: "scanner",
            active: false,
          };
        }
        return null;
      })
      .filter((scanner) => scanner !== null);
    const categoryNames = Array.from(
      new Set(cveData.map((cve) => cve.scan_category))
    ).map((category) => ({
      id: category,
      name: category,
      type: "Category: " + category,
      key: "22",
      active: false,
    }));
    setTabs(
      securityIssuesTabsConfig({
        uniqueDomains,
        uniqueHost,
        uniqueIpLists,
        Exploitabilities,
        impacts,
        scores,
        titles,
        criticalityFilterList,
        firstSeenFilterOptions,
        daysOpen,
        CVSS,
        EPSS,
        cisaKevFilterList,
        armoryTestFilterList,
        issueStateFilterList:
          type === "history"
            ? issueStatusHistoryFilterList
            : issueStateFilterList,
        scanners,
        categoryNames,
        type,
        warRoomFilterList,
        hasCVEFilterList,
        newTagList,
      })
    );
  };

  const fetchCves = async (id) => {
    setIsLoading(true);
    const endpoint =
      type === "history" ? `/issues/${id}/history` : `/issues/${id}/open`;
    try {
      const {
        data: {
          results = [],
          scanning,
          completionTime,
          items = [],
          countGroups,
        },
      } = await axios.get(endpoint);
      const originalData = results;
      if (results.length === 0 && items?.length === 0) {
        setOriginalCves([]);
        setSeparatedCves([]);
        setCves([]);
        updateTabsWithData([]);
        setIsLoading(false);
        setIsFinished(completionTime !== null);
        completionTime !== null &&
          updateScanningDetails({
            status: scanning,
            date: completionTime,
          });
        return;
      }
      setTotalNewIssues(countGroups?.total_new_count || 0);
      setOriginalCves(originalData);
      setSeparatedCves(items);

      const filteredData = ipParam
        ? items.filter((cve) => cve.ip === ipParam)
        : items;
      if (portParam) {
        handleIpPortParamClick(ipParam, armoryIdParam, portParam, originalData);
      } else {
        handleIpParamClick(ipParam, armoryIdParam, portParam, originalData);
      }
      handleDomainParamClick(domainParam, armoryIdParam, originalData);
      handleHostParamClick(hostParam);
      handleRiskParamClick(riskParam);
      handleScannerServiceClick(scannerParam);
      if (issueStatus) {
        handleIssueClick("Solved");
      }
      if (issueParam) {
        handleIssueParamClick(issueParam, originalData);
      }

      if (type === "open") {
        const labels = ["Open", "Re-open", "Pending Validation"];
        const data = [
          countGroups.open || 0,
          countGroups.reopen || 0,
          countGroups.pv || 0,
        ];

        if (countGroups.vf) {
          labels.push("Validation Failed");
          data.push(countGroups.vf);
        }

        setDoughnutChartType({
          labels: labels,
          datasets: [
            {
              data: data,
              backgroundColor: openColorList,
              hoverBackgroundColor: openColorList,
            },
          ],
        });
      } else if (type === "history") {
        setDoughnutChartType({
          labels: ["Solved", "Acknowledged", "False-Positive"],
          datasets: [
            {
              data: [
                countGroups.solved || 0,
                countGroups.ack || 0,
                countGroups.fp || 0,
              ],
              backgroundColor: historycolorList,
              hoverBackgroundColor: historycolorList,
            },
          ],
        });
      }
      setCves(filteredData);
      updateTabsWithData(originalData);
      setIsLoading(false);
      updateScanningDetails({
        status: scanning,
        date: completionTime,
      });
    } catch (err) {
      if (err?.response?.data?.status == false) {
        setIsFinished(false);
        updateScanningDetails({
          status: "Temporary error",
          date: new Date(),
        });
      }
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (routeParams?.id) {
      fetchCves(routeParams.id);
    }
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [routeParams.id, scannerParam, type]);
  const filterCategories = [
    { key: "2", data: assets, field: "assets", isAsset: true },
    { key: "4", data: impacts, field: "impact" },
    { key: "5", data: Exploitabilities, field: "exploitability" },
    { key: "6", data: CVSS, field: "cvss" },
    { key: "7", data: EPSS, field: "epss" },
    { key: "8", data: scores, field: "risk_score" },
  ];

  const groupedFilters = activeFilters.reduce((acc, filter) => {
    if (!acc[filter.eventKey]) {
      acc[filter.eventKey] = [];
    }
    acc[filter.eventKey].push(filter);
    return acc;
  }, {});

  const statusMap = {
    Open: { value: 1 },
    "Re-open": { value: 2 },
    "Pending Validation": { value: 3 },
    "Validation Failed": { value: 4 },
    Acknowledged: { value: 7 },
    Solved: { value: 5 },
    "False-Positive": { value: 6 },
  };
  const currentStatusMap = { new: 1, changed: 2, unchanged: 0 };

  const assetsCritical = {
    low: 1,
    medium: 2,
    high: 3,
  };

  const filteredCves = useMemo(() => {
    const dataToFilter = isChecked ? originalCves : separatedCves;

    if (!activeFilters.length && searchValue === "") {
      return sortData(dataToFilter, isSortBy);
    }

    let filteredData = dataToFilter
      .filter((cve) => {
        const matchesFilters = Object.keys(groupedFilters).every((eventKey) => {
          return groupedFilters[eventKey].some((filter) => {
            if (eventKey === "1") {
              return (
                (filter.name === "-" &&
                  (!cve.issue_name || cve.issue_name === "-")) ||
                (cve.issue_name &&
                  cve.issue_name.toLowerCase() === filter.name.toLowerCase())
              );
            } else if (eventKey === "3") {
              return (
                (filter.name === "-" &&
                  (!cve.scan_category || cve.scan_category === "-")) ||
                (cve.scan_category &&
                  cve.scan_category.toLowerCase() === filter.name.toLowerCase())
              );
            } else if (eventKey === "9") {
              return (
                (filter.name.toLowerCase() === "no" && !cve.cve) ||
                (filter.name.toLowerCase() === "yes" && cve.cve)
              );
            } else if (eventKey === "scanner") {
              const service = serviceMap.find(
                (s) => s.serviceName === filter.name
              );
              if (service.id == 25) {
                return cve.service_id === 2 || cve.service_id === 18
                  ? true
                  : false;
              } else {
                return service ? cve.service_id === service.id : false;
              }
            } else if (eventKey === "8") {
              const filterName = filter.name;
              const match = filterName.match(/(\d+)-(\d+)/);
              if (match) {
                const [min, max] = match.slice(1, 3).map(Number);
                return cve.risk_score >= min && cve.risk_score <= max;
              }
              return false;
            } else if (
              eventKey === "ip" ||
              eventKey === "12" ||
              eventKey === "port"
            ) {
              const portParamString = portParam ? String(portParam) : null;

              if (portParam) {
                return (
                  (String(cve.ip) === String(filter.name) &&
                    String(cve.port) === portParamString) ||
                  cve.assets?.some(
                    (asset) =>
                      String(asset.ip) === String(filter.name) &&
                      String(asset.port) === portParamString
                  )
                );
              } else {
                return (
                  String(cve.ip) === String(filter.name) ||
                  cve.assets?.some(
                    (asset) => String(asset.ip) === String(filter.name)
                  )
                );
              }
            } else if (eventKey === "issueStatus") {
              const filterNameLowerCase = filter.name;
              const mappedStatus = statusMap[filterNameLowerCase]?.value;
              if (mappedStatus) {
                return isChecked
                  ? cve.assets?.some(
                      (asset) => asset.issue_status === mappedStatus
                    )
                  : cve.issue_status === mappedStatus;
              } else {
                return false;
              }
            } else if (eventKey === "domain" || eventKey === "10") {
              return (
                cve.domain &&
                cve.domain.toLowerCase() === filter.name.toLowerCase()
              );
            } else if (eventKey === "host" || eventKey === "11") {
              if (isChecked) {
                return cve.assets?.some(
                  (asset) =>
                    asset.host?.toLowerCase() === filter.name.toLowerCase()
                );
              } else {
                return cve.host?.toLowerCase() === filter.name.toLowerCase();
              }
            } else if (eventKey === "13") {
              return (
                cve.issue_name &&
                cve.issue_name.toLowerCase() === filter.name.toLowerCase()
              );
            } else if (eventKey === "22") {
              return (
                cve.scan_category &&
                cve.scan_category.toLowerCase() === filter.name.toLowerCase()
              );
            } else if (eventKey === "14") {
              return (
                cve.asset_criticality_host ===
                assetsCritical[filter.name.toLowerCase()]
              );
            } else if (eventKey === "16") {
              return Number(cve?.days_open) == Number(filter.name);
            } else if (eventKey === "17") {
              return (
                (filter.name.toLowerCase() === "no" && cve.cisa_kev == "no") ||
                (filter.name.toLowerCase() === "yes" && cve.cisa_kev == "yes")
              );
            } else if (eventKey === "27") {
              if (isChecked) {
                return cve.assets?.some(
                  (asset) =>
                    (filter.name.toLowerCase() === "no" && !asset.new) ||
                    (filter.name.toLowerCase() === "yes" && asset.new)
                );
              } else {
                return (
                  (filter.name.toLowerCase() === "no" && !cve.new) ||
                  (filter.name.toLowerCase() === "yes" && cve.new)
                );
              }
            } else if (eventKey === "25") {
              return (
                (filter.name.toLowerCase() === "no" && cve.is_war_room == 0) ||
                (filter.name.toLowerCase() === "yes" && cve.is_war_room == 1)
              );
            } else if (eventKey === "26") {
              return (
                (filter.name.toLowerCase() === "no" && !cve.cve) ||
                (filter.name.toLowerCase() === "yes" && cve.cve)
              );
            } else if (eventKey === "19") {
              return (
                (filter.name.toLowerCase() === "no" &&
                  cve.armory_tested == 0) ||
                (filter.name.toLowerCase() === "yes" && cve.armory_tested == 1)
              );
            } else if (eventKey === "20") {
              const filterNameLowerCase = filter.name;
              const mappedStatus = statusMap[filterNameLowerCase]?.value;
              if (mappedStatus) {
                return isChecked
                  ? cve.assets?.some(
                      (asset) => asset.issue_status === mappedStatus
                    )
                  : cve.issue_status === mappedStatus;
              } else {
                return false;
              }
            } else if (eventKey === "15") {
              return applyCondition(
                cve.first_seen_at,
                filter.name.toLowerCase().replaceAll(" ", "_"),
                filter.name
              );
            } else if (eventKey === "24") {
              return applyCondition(
                cve.last_seen_at,
                filter.name.toLowerCase().replaceAll(" ", "_"),
                filter.name
              );
            } else if (eventKey === "25") {
              return (
                (filter.name.toLowerCase() === "no" && cve.is_war_room == 0) ||
                (filter.name.toLowerCase() === "yes" && cve.is_war_room == 1)
              );
            } else if (eventKey === "26") {
              return (
                (filter.name.toLowerCase() === "no" && !cve.cve) ||
                (filter.name.toLowerCase() === "yes" && cve.cve)
              );
            } else if (eventKey === "18") {
              return (
                cve?.status === currentStatusMap[filter.name.toLowerCase()]
              );
            } else if (eventKey === "advanced-filter") {
              const parsedFilters = parseFilterString(filter.name);
              return parsedFilters.every((ol) => {
                const { column, condition, value } = ol;
                switch (column) {
                  case "domain":
                    if (isChecked) {
                      return cve.assets?.some((asset) =>
                        applyCondition(
                          asset.domain?.toLowerCase(),
                          condition,
                          value?.toLowerCase()
                        )
                      );
                    } else {
                      return applyCondition(
                        cve.domain?.toLowerCase(),
                        condition,
                        value?.toLowerCase()
                      );
                    }
                  case "host":
                    if (isChecked) {
                      return cve.assets?.some((asset) =>
                        applyCondition(
                          asset.host?.toLowerCase(),
                          condition,
                          value?.toLowerCase()
                        )
                      );
                    } else {
                      return applyCondition(
                        cve.host?.toLowerCase(),
                        condition,
                        value?.toLowerCase()
                      );
                    }
                  case "ip":
                    if (isChecked) {
                      return cve.assets?.some((asset) =>
                        applyCondition(
                          asset.ip?.toLowerCase(),
                          condition,
                          value?.toLowerCase()
                        )
                      );
                    } else {
                      return applyCondition(
                        cve.ip?.toLowerCase(),
                        condition,
                        value?.toLowerCase()
                      );
                    }
                  case "exploitability":
                    if (
                      value == "0-40" ||
                      value == "40-80" ||
                      value == "80-100"
                    ) {
                      const startValue =
                        value == "0-40" ? 0 : value == "40-80" ? 40 : 80;
                      const endValue =
                        value == "0-40" ? 40 : value == "40-80" ? 80 : 100;
                      const isExpo =
                        cve?.exploitability > startValue &&
                        cve?.exploitability <= endValue;
                      if (condition == "is") {
                        return isExpo;
                      } else if (condition == "is_not") {
                        return !isExpo;
                      } else if (condition == "above") {
                        return cve?.exploitability > endValue;
                      } else if (condition == "below") {
                        return cve?.exploitability < startValue;
                      }
                    } else {
                      return applyCondition(
                        `${cve?.exploitability}`,
                        condition,
                        value
                      );
                    }
                  case "impact":
                    if (
                      value == "0-40" ||
                      value == "40-80" ||
                      value == "80-100"
                    ) {
                      const startValue =
                        value == "0-40" ? 0 : value == "40-80" ? 40 : 80;
                      const endValue =
                        value == "0-40" ? 40 : value == "40-80" ? 80 : 100;
                      const isExpo =
                        cve?.impact > startValue && cve?.impact <= endValue;
                      if (condition == "is") {
                        return isExpo;
                      } else if (condition == "is_not") {
                        return !isExpo;
                      } else if (condition == "above") {
                        return cve?.impact > endValue;
                      } else if (condition == "below") {
                        return cve?.impact < startValue;
                      }
                    } else {
                      return applyCondition(`${cve?.impact}`, condition, value);
                    }
                  case "risk_score":
                    if (
                      value == "0-39" ||
                      value == "40-79" ||
                      value == "80-100"
                    ) {
                      const startValue =
                        value == "0-39" ? 0 : value == "40-79" ? 40 : 80;
                      const endValue =
                        value == "0-39" ? 39 : value == "40-79" ? 79 : 100;
                      const isExpo =
                        cve?.risk_score >= startValue &&
                        cve?.risk_score <= endValue;
                      if (condition == "is") {
                        return isExpo;
                      } else if (condition == "is_not") {
                        return !isExpo;
                      } else if (condition == "above") {
                        return cve?.risk_score > endValue;
                      } else if (condition == "below") {
                        return cve?.risk_score < startValue;
                      }
                    } else {
                      return applyCondition(
                        `${cve?.risk_score}`,
                        condition,
                        value
                      );
                    }
                  case "issue_name":
                    return applyCondition(
                      cve.issue_name?.toLowerCase(),
                      condition,
                      value?.toLowerCase()
                    );
                  case "asset_criticality":
                    if (isChecked) {
                      return cve.assets?.some((asset) =>
                        applyCondition(
                          asset.asset_criticality_host,
                          condition,
                          assetsCritical?.[value.toLowerCase()]
                        )
                      );
                    } else {
                      return applyCondition(
                        cve.asset_criticality_host,
                        condition,
                        assetsCritical?.[value.toLowerCase()]
                      );
                    }
                  case "days_open":
                    if (isChecked) {
                      return cve.assets?.some((asset) =>
                        applyCondition(`${asset?.days_open}`, condition, value)
                      );
                    } else {
                      return applyCondition(
                        `${cve?.days_open}`,
                        condition,
                        value
                      );
                    }
                  case "cvss":
                    if (value == "0-4" || value == "4-8" || value == "8-10") {
                      const startValue =
                        value == "0-4" ? 0 : value == "4-8" ? 4 : 8;
                      const endValue =
                        value == "0-4" ? 4 : value == "4-8" ? 8 : 10;
                      const isExpo =
                        cve?.cvss > startValue && cve?.cvss <= endValue;
                      if (condition == "is") {
                        return isExpo;
                      } else if (condition == "is_not") {
                        return !isExpo;
                      } else if (condition == "above") {
                        return cve?.cvss > endValue;
                      } else if (condition == "below") {
                        return cve?.cvss < startValue;
                      }
                    } else {
                      return applyCondition(`${cve?.cvss}`, condition, value);
                    }
                  case "epss":
                    if (
                      value == "0.0-0.4" ||
                      value == "0.4-0.8" ||
                      value == "0.8-1"
                    ) {
                      const startValue =
                        value == "0.0-0.4"
                          ? 0.0
                          : value == "0.4-0.8"
                          ? 0.4
                          : 0.8;
                      const endValue =
                        value == "0.0-0.4" ? 0.4 : value == "0.4-0.8" ? 0.8 : 1;
                      const isExpo =
                        cve?.epss > startValue && cve?.epss <= endValue;
                      if (condition == "is") {
                        return isExpo;
                      } else if (condition == "is_not") {
                        return !isExpo;
                      } else if (condition == "above") {
                        return cve?.epss > endValue;
                      } else if (condition == "below") {
                        return cve?.epss < startValue;
                      }
                    } else {
                      return applyCondition(`${cve?.epss}`, condition, value);
                    }
                  case "cisa_kev":
                    return (
                      (value.toLowerCase() === "no" && cve.cisa_kev == "no") ||
                      (value.toLowerCase() === "yes" && cve.cisa_kev == "yes")
                    );
                  case "is_new":
                    if (isChecked) {
                      return cve.assets?.some(
                        (asset) =>
                          (value.toLowerCase() === "no" && !asset.new) ||
                          (value.toLowerCase() === "yes" && asset.new)
                      );
                    } else {
                      return (
                        (value.toLowerCase() === "no" && !cve.new) ||
                        (value.toLowerCase() === "yes" && cve.new)
                      );
                    }
                  case "status":
                    if (isChecked) {
                      return cve.assets?.some((asset) =>
                        applyCondition(
                          asset.status,
                          condition,
                          currentStatusMap[value.toLowerCase()]
                        )
                      );
                    } else {
                      return applyCondition(
                        cve.status,
                        condition,
                        currentStatusMap[value.toLowerCase()]
                      );
                    }
                  case "armory_tested":
                    return (
                      (value.toLowerCase() === "no" &&
                        cve.armory_tested == 0) ||
                      (value.toLowerCase() === "yes" && cve.armory_tested == 1)
                    );
                  case "is_war_room":
                    return (
                      (value.toLowerCase() === "no" && cve.is_war_room == 0) ||
                      (value.toLowerCase() === "yes" && cve.is_war_room == 1)
                    );
                  case "has_cve":
                    return (
                      (value.toLowerCase() === "no" && !cve.cve) ||
                      (value.toLowerCase() === "yes" && cve.cve)
                    );
                  case "issue_status":
                    if (condition === "is_not") {
                      return (
                        (value.toLowerCase() === "open" &&
                          cve.issue_status !== 1) ||
                        (value.toLowerCase() === "re-open" &&
                          cve.issue_status !== 2) ||
                        (value.toLowerCase() === "pending validation" &&
                          cve.issue_status !== 3)
                      );
                    } else {
                      return (
                        (value.toLowerCase() === "open" &&
                          cve.issue_status == 1) ||
                        (value.toLowerCase() === "re-open" &&
                          cve.issue_status == 2) ||
                        (value.toLowerCase() === "pending validation" &&
                          cve.issue_status == 3)
                      );
                    }
                  case "first_seen_at":
                    if (isChecked) {
                      return cve.assets?.some((asset) =>
                        applyCondition(asset.created_at, condition, value)
                      );
                    } else {
                      return applyCondition(
                        cve.first_seen_at,
                        condition,
                        value
                      );
                    }
                  case "last_seen_at":
                    return applyCondition(cve?.last_seen_at, condition, value);
                  case "scan_category":
                    return applyCondition(
                      cve?.scan_category?.toLowerCase(),
                      condition,
                      value?.toLowerCase()
                    );
                  default:
                    return false;
                }
              });
            } else {
              return filterCategories.some((category) => {
                if (eventKey === category.key) {
                  if (category.field === "assets") {
                    return cve.assets?.some((asset) => {
                      let assetName = asset.ip ? asset.ip : "";
                      if (asset.port) {
                        assetName += ` Port: ${asset.port}`;
                      } else if (asset.domain) {
                        assetName = asset.domain;
                      } else if (asset.host) {
                        assetName = asset.host;
                      }
                      assetName = assetName.trim().toLowerCase();
                      const filterName = filter.name.trim().toLowerCase();
                      return assetName === filterName;
                    });
                  } else {
                    const score = cve[category.field] || 0;
                    const [min, max] = filter.name.split("-").map(Number);
                    return score >= min && score <= max;
                  }
                }
                return false;
              });
            }
          });
        });

      // Apply search value
      const matchesSearchValue =
        searchValue === "" ||
        (isChecked
          ? // Search within assets when isChecked is true
            cve.assets?.some(
              (el) =>
                (el.ip &&
                  el.ip.toLowerCase().includes(searchValue.toLowerCase())) ||
                (el.port &&
                  String(el.port)
                    .toLowerCase()
                    .includes(searchValue.toLowerCase())) ||
                (el.domain &&
                  el.domain
                    .toLowerCase()
                    .includes(searchValue.toLowerCase())) ||
                (el.host &&
                  el.host.toLowerCase().includes(searchValue.toLowerCase())) ||
                (cve.issue_name &&
                  cve.issue_name
                    .toLowerCase()
                    .includes(searchValue.toLowerCase()))
            )
          : // Search within cve fields when isChecked is false
            (cve.issue_name &&
              cve.issue_name
                .toLowerCase()
                .includes(searchValue.toLowerCase())) ||
            (cve.scan_category &&
              cve.scan_category
                .toLowerCase()
                .includes(searchValue.toLowerCase())) ||
            (cve.ip &&
              cve.ip.toLowerCase().includes(searchValue.toLowerCase())) ||
            (cve.port &&
              String(cve.port)
                .toLowerCase()
                .includes(searchValue.toLowerCase())) ||
            (cve.domain &&
              cve.domain.toLowerCase().includes(searchValue.toLowerCase())) ||
            (cve.host &&
              cve.host.toLowerCase().includes(searchValue.toLowerCase())));

        // Apply risk level filter
        const matchesRiskLevel =
          !selectedRiskLevel ||
          (selectedRiskLevel === "high" &&
            cve.risk_score >= 80 &&
            cve.risk_score <= 100) ||
          (selectedRiskLevel === "medium" &&
            cve.risk_score >= 40 &&
            cve.risk_score <= 79) ||
          (selectedRiskLevel === "low" &&
            cve.risk_score >= 0 &&
            cve.risk_score <= 39);

        // Apply issue status filter
        const matchesIssueStatus =
          !selectedIssueStatus ||
          (isChecked
            ? cve.assets?.some((asset) =>
                selectedIssueStatus?.includes(asset.issue_status)
              )
            : selectedIssueStatus?.includes(cve.issue_status));

        // Return only the CVEs that match filters, search, risk level, and issue status
        return (
          matchesFilters &&
          matchesSearchValue &&
          matchesRiskLevel &&
          matchesIssueStatus
        );
      })
      .map((el) => {
        const allTitle = activeFilters.every(
          (filter) =>
            filter.eventKey == "13" ||
            filter.eventKey == "2" ||
            filter.eventKey == "4" ||
            filter.eventKey == "6" ||
            filter.eventKey == "8" ||
            filter.eventKey == "7"
        );
        return {
          ...el,
          assets: el?.assets?.filter(
            (asset) =>
              activeFilters.length === 0 ||
              allTitle ||
              (groupedFilters["27"] &&
                groupedFilters["27"].some(
                  (filter) =>
                    (filter.name.toLowerCase() === "no" && !asset.new) ||
                    (filter.name.toLowerCase() === "yes" && asset.new)
                )) ||
              (groupedFilters["advanced-filter"] &&
                groupedFilters["advanced-filter"].some((filter) => {
                  const parsedFilters = parseFilterString(filter.name);
                  const withoutIpFilter = parsedFilters?.filter(
                    (el) =>
                      el.column != "exploitability" &&
                      el.column != "impact" &&
                      el.column != "risk_score" &&
                      el.column != "cvss" &&
                      el.column != "epss" &&
                      el.column != "issue_name"
                  );

                  return withoutIpFilter.every((ol) => {
                    const { column, value } = ol;
                    switch (column) {
                      case "is_new":
                        return (
                          (value?.toLowerCase() === "yes" && asset.new) ||
                          (value?.toLowerCase() === "no" && !asset.new)
                        );
                      default:
                        return false;
                    }
                  });
                }))
          ),
        };
      });
    // Sort the filtered data
    return sortData(filteredData, isSortBy);
  }, [
    isChecked,
    separatedCves,
    originalCves,
    groupedFilters,
    searchValue,
    selectedRiskLevel,
    selectedIssueStatus,
    filterCategories,
    activeFilters,
    isSortBy,
  ]);

  let highRisk = 0;
  let mediumRisk = 0;
  let lowRisk = 0;
  cves?.map((el) => {
    if (el.risk_score >= 80 && el.risk_score <= 100) {
      highRisk += 1;
    } else if (el.risk_score >= 40 && el.risk_score <= 79) {
      mediumRisk += 1;
    } else if (el.risk_score >= 0 && el.risk_score <= 39) {
      lowRisk += 1;
    }
  });
  const handleRiskClick = (riskLevel) => {
    let riskFilterName = "";

    switch (riskLevel) {
      case "high":
        riskFilterName = "80-100";
        break;
      case "medium":
        riskFilterName = "40-79";
        break;
      case "low":
        riskFilterName = "0-39";
        break;
      default:
        riskFilterName = "";
    }
    setActiveFilters(
      webUpdatesFilter(riskFilterName, activeFilters, "8", "Risk Score")
    );
  };

  const handleSwitchChange = (e) => {
    const isSwitchChecked = e.target.checked;
    setIsChecked(isSwitchChecked);
    const newCves = isSwitchChecked ? originalCves : separatedCves;
    setCves(newCves);
    if (!isSwitchChecked) {
      setCollapsedGroups(newCves.map(() => true));
    }
  };

  const toggleCollapse = (groupName) => {
    setCollapsedGroups((prevState) => ({
      ...prevState,
      [groupName]: !prevState[groupName],
    }));
  };
  useEffect(() => {
    // Default all groups to collapsed (hidden)
    const initialCollapsedState = cves.reduce((acc, group) => {
      acc[group.issue_name] = false;
      return acc;
    }, {});
    setCollapsedGroups(initialCollapsedState);
  }, [cves]);

  const handleIssueClick = (issueStatus) => {
    setActiveFilters(
      webUpdatesFilter(issueStatus, activeFilters, "20", "Issue Status")
    );
  };
  const handleNewFilterClick = () => {
    setActiveFilters(webUpdatesFilter("Yes", activeFilters, "27", "New"));
  };
  const handleIpPortParamClick = (ipParam, armoryIdParam, portParam, data) => {
    if (
      (!ipParam && !armoryIdParam) ||
      !Array.isArray(data) ||
      data.length === 0
    ) {
      return;
    }

    const portParamNumber = portParam ? Number(portParam) : null;
    const filteredByParams = data.filter((cve) => {
      const cvePort = cve.port ? Number(cve.port) : null;

      const matchesIp =
        cve.ip === ipParam || cve.assets?.some((asset) => asset.ip === ipParam);

      const matchesPort =
        cvePort === portParamNumber ||
        cve.assets?.some((asset) => Number(asset.port) === portParamNumber);
      const matchesArmoryId = cve.armory_id === armoryIdParam;

      if (portParamNumber) {
        return matchesIp && matchesPort;
      } else {
        return armoryIdParam ? matchesArmoryId : matchesIp;
      }
    });

    const firstIssueName = filteredByParams.length
      ? filteredByParams[0].issue_name
      : null;

    setActiveFilters((currentFilters) => {
      const filteredFilters = currentFilters.filter(
        (filter) => filter.eventKey !== "ip" && filter.eventKey !== "1"
      );

      let updatedFilters = ipParam
        ? webUpdatesFilter(ipParam, filteredFilters, "ip", "Asset")
        : filteredFilters;

      if (firstIssueName && portParamNumber) {
        updatedFilters = webUpdatesFilter(
          firstIssueName,
          updatedFilters,
          "1",
          "Issue Name"
        );
      }

      return updatedFilters;
    });
  };

  const handleIpParamClick = (ipParam, armoryIdParam, portParam, data) => {
    if (
      (!ipParam && !armoryIdParam) ||
      !Array.isArray(data) ||
      data.length === 0
    ) {
      return;
    }

    const portParamNumber = portParam ? Number(portParam) : null;

    const filteredByParams = data.filter((cve) => {
      // Check IP and assets IP matches
      const matchesIp = ipParam
        ? cve.ip === ipParam ||
          cve.assets?.some((asset) => asset.ip === ipParam)
        : true;

      // Check if the armory ID matches
      const matchesArmoryId = armoryIdParam
        ? cve.armory_id === armoryIdParam
        : true;

      // Check if port number matches if provided
      const matchesPort =
        portParamNumber !== null
          ? cve.port === portParamNumber ||
            cve.assets?.some((asset) => asset.port === portParamNumber)
          : true; // Ignore port matching if not provided

      // Filter based on IP, Armory ID, and optionally port
      return matchesIp && matchesArmoryId && matchesPort;
    });

    const firstIssueName = filteredByParams.length
      ? filteredByParams[0].issue_name
      : null;

    // Update active filters based on IP and Armory ID
    setActiveFilters((currentFilters) => {
      const filteredFilters = currentFilters.filter(
        (filter) =>
          filter.eventKey !== "ip" &&
          filter.eventKey !== "armoryIdParam" &&
          filter.eventKey !== "1" // Remove existing issue_name filter
      );

      // Add the IP filter if available
      let updatedFilters = ipParam
        ? webUpdatesFilter(ipParam, filteredFilters, "ip", "Asset")
        : filteredFilters;
      // Add the Issue Name filter if present, regardless of the port number
      if (firstIssueName && armoryIdParam) {
        updatedFilters = webUpdatesFilter(
          firstIssueName,
          updatedFilters,
          "1",
          "Issue Name"
        );
      }

      return updatedFilters;
    });
  };

  const handleDomainParamClick = (domainParam, armoryIdParam, data) => {
    if (
      (!domainParam && !armoryIdParam) ||
      !Array.isArray(data) ||
      data.length === 0
    )
      return;
    const filteredByParams = data.filter((cve) => {
      const matchesDomain =
        cve.domain === domainParam ||
        cve.assets?.some((asset) => asset.domain === domainParam);
      const matchesArmoryId = cve.armory_id === armoryIdParam;
      return armoryIdParam ? matchesArmoryId : matchesDomain;
    });
    const issueNames = Array.from(
      new Set(filteredByParams.map((cve) => cve.issue_name).filter(Boolean))
    );
    setActiveFilters((currentFilters) => {
      const filteredFilters = currentFilters.filter(
        (filter) =>
          filter.eventKey !== "domain" &&
          filter.eventKey !== "armoryIdParam" &&
          filter.eventKey !== "1"
      );
      const updatedFilters = domainParam
        ? webUpdatesFilter(domainParam, filteredFilters, "domain", "Asset")
        : filteredFilters;
      return issueNames.reduce(
        (accumulatedFilters, issueName) =>
          webUpdatesFilter(
            `${issueName}`,
            accumulatedFilters,
            "1",
            "Issue Name"
          ),
        updatedFilters
      );
    });
  };

  const handleHostParamClick = (hostParam) => {
    if (!hostParam) return;
    setActiveFilters((currentFilters) =>
      webUpdatesFilter(
        hostParam,
        currentFilters.filter((filter) => filter.eventKey !== "host"),
        "host",
        "Asset"
      )
    );
  };

  const handleRiskParamClick = (riskParam) => {
    if (!riskParam) return;
    setActiveFilters((currentFilters) =>
      webUpdatesFilter(
        riskParam,
        currentFilters.filter((filter) => filter.eventKey !== "8"),
        "8",
        "Risk Score"
      )
    );
  };

  const handleScannerServiceClick = (scannerParam) => {
    if (!scannerParam) return;
    const service = serviceMap.find((s) => s.id === parseInt(scannerParam, 10));

    if (service) {
      setActiveFilters((currentFilters) => {
        const filteredFilters = currentFilters.filter(
          (filter) => filter.eventKey !== "scanner"
        );
        const updatedFilters = webUpdatesFilter(
          `${service.serviceName}`,
          filteredFilters,
          "scanner",
          "Scanner"
        );
        return updatedFilters;
      });
    }
  };

  const handleIssueParamClick = (issueParam, data) => {
    if (!issueParam || !Array.isArray(data) || data.length === 0) return;
    let updatedFilters = webUpdatesFilter(
      issueParam,
      activeFilters,
      "1",
      "Issue Name"
    );
    setActiveFilters(updatedFilters);
  };

  const handleClearSelectRow = () => {
    setSelectedItems([]);
    setClearRowsTrigger((prev) => prev + 1);
  };
  const handleActionClick = (actionType, data, type) => {
    setModalData({ actionType, data, type });
    setShowModal(true);
  };
  const handleSelection = (
    selectItem,
    isSelected,
    itemId,
    isGrouped = false,
    isSingleSelection = false
  ) => {
    if (isGrouped && !isSingleSelection) {
      if (isSelected) {
        // Add all assets in the group to selectedCves
        setSelectedCves((prev) => [
          ...prev,
          ...selectItem.assets
            .filter((asset) => !prev.some((item) => item.id === asset.id))
            .map((asset) => ({
              ...asset,
              groupInfo: selectItem.groupInfo, // Include group information for tracking
            })),
        ]);
      } else {
        // Remove all assets in the group from selectedCves
        setSelectedCves((prev) =>
          prev.filter(
            (item) => !selectItem.assets.some((asset) => asset.id === item.id)
          )
        );
      }

      // Update the checked state for the group and its items
      setCheckedState((prev) => {
        const newCheckedState = { ...prev };
        // Update the group checkbox state
        newCheckedState[selectItem.groupInfo] = isSelected;
        // Update each individual asset's checkbox state
        selectItem.assets.forEach((asset) => {
          newCheckedState[asset.id] = isSelected;
        });
        return newCheckedState;
      });
    } else {
      // Handle individual asset selection
      if (isSelected) {
        // Add individual asset to selectedCves
        setSelectedCves((prev) => [...prev, selectItem]);
      } else {
        // Remove individual asset from selectedCves
        setSelectedCves((prev) =>
          prev.filter((item) => item.id !== selectItem.id)
        );
      }

      // Update the checked state for the individual asset
      setCheckedState((prev) => ({
        ...prev,
        [itemId]: isSelected,
      }));
    }
  };

  const handleSelectAll = () => {
    if (isChecked) {
      const allSelectedCves = [];

      filteredCves.forEach((issue) => {
        if (issue.assets && issue.assets.length > 0) {
          allSelectedCves.push(
            ...issue.assets.map((asset) => ({
              ...asset,
              groupInfo: issue.issue_name,
            }))
          );
        } else {
          allSelectedCves.push(issue);
        }
      });

      setSelectedCves(allSelectedCves);
      const newCheckedState = {};
      filteredCves.forEach((issue) => {
        newCheckedState[issue.issue_name] = true;
        if (issue.assets) {
          issue.assets.forEach((asset) => {
            newCheckedState[asset.id] = true;
          });
        } else {
          newCheckedState[issue.id] = true;
        }
      });

      setCheckedState(newCheckedState);
    } else {
      const allSelected = isChecked
        ? filteredCves.map((item) => ({
            groupInfo: item.issue_name,
            armory_id: item.armory_id,
            assets: item.assets,
          }))
        : filteredCves;

      const newCheckedState = {};
      filteredCves.forEach((item) => {
        const itemId = item.target_isssue_id || item.issue_name;
        newCheckedState[itemId] = true;
      });

      setSelectedCves(allSelected);
      setCheckedState(newCheckedState);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    setModalData(null);
  };
  const handleSelectionAll = () => {
    SetIsBulkSelectedAll(true);
  };
  const onConfirm = (value, type) => {
    if (value === 6 || value === 7) {
      activeTab("History");
    } else if (value === 2) {
      activeTab("Open Issues");
    }
    if (type === "Solve") {
      fetchCves(routeParams.id);
    }
    setShowModal(false);
    setModalData(null);
  };
  useEffect(() => {
    setIsChecked(false);
    setActiveFilters([]);
  }, [type]);

  useEffect(() => {
    if (isNew) {
      handleNewFilterClick();
    }
  }, [isNew]);

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="content-loader">
          <ThreeCircles
            visible={true}
            height="60"
            width="60"
            color="#ffff"
            ariaLabel="three-circles-loading"
            wrapperClass=""
          />
        </div>
      ) : (
        <div>
          <div>
            {cves?.length > 0 ? (
              <div className="main_container_style ">
                <div className="row issues-container-card m-0">
                  <div
                    className={`col-${type == "open" ? "5" : "6"}`}
                    style={{
                      padding: type == "open" ? "0px" : "0px 10px 0px 0px",
                      width: type == "open" ? "42.5%" : "50%",
                    }}
                  >
                    <HighlightsRisk
                      highRisk={highRisk}
                      mediumRisk={mediumRisk}
                      lowRisk={lowRisk}
                      onRiskClick={handleRiskClick}
                    />
                  </div>
                  {type == "open" && (
                    <div
                      className="col-2 issue-status-card"
                      style={{
                        padding: "0px 15px",
                        width: "15%",
                      }}
                    >
                      <GenericCard
                        children={
                          <div>
                            <div
                              className="total-new-issues-title"
                              onClick={() => {
                                if (totalNewIssues > 0) {
                                  handleNewFilterClick();
                                }
                              }}
                            >
                              {totalNewIssues}
                            </div>
                          </div>
                        }
                        title={"Total New Issues"}
                      />
                    </div>
                  )}
                  <div
                    className={`col-${
                      type == "open" ? "5" : "6"
                    } issue-status-card`}
                    style={{
                      padding: type == "open" ? "0px" : "0px 0px 0px 10px",
                      width: type == "open" ? "42.5%" : "50%",
                    }}
                  >
                    <GenericCard
                      children={
                        <IssueStatusChart
                          data={doughnutChartType}
                          options={doughnutChartOptions}
                          onIssueClick={handleIssueClick}
                        />
                      }
                      title={"Issue Status"}
                    />
                  </div>
                </div>
                <div className="mt-4 mb-3">
                  <GroupSwitch
                    id="group-switch"
                    label="Group Issues by Title"
                    checked={isChecked}
                    onChange={handleSwitchChange}
                    className="group-switch"
                  />
                </div>
                <div className="Toast-main">
                  {" "}
                  <ToastContainer />
                </div>
                <OptionsSection
                  cves={filteredCves}
                  filteredCves={filteredCves}
                  tabs={tabs}
                  handleFilterChange={handleFilterChange}
                  activeFilters={activeFilters}
                  removeFilter={removeFilter}
                  handleSortChange={handleSortChange}
                  searchValue={searchValue}
                  handleSearchChange={handleSearchChange}
                  selectedSortValue={selectedSortValue}
                  isChecked={isChecked}
                  selectedItems={selectedItems}
                  onClearSelectRow={handleClearSelectRow}
                  handleActionClick={handleActionClick}
                  actions={actions}
                  handleSelectionAll={handleSelectionAll}
                />
                <CveList
                  isChecked={isChecked}
                  filteredCves={filteredCves}
                  collapsedGroups={collapsedGroups}
                  toggleCollapse={toggleCollapse}
                  SetSelectedItems={setSelectedItems}
                  clearSelectedRows={clearRowsTrigger}
                  handleActionClick={handleActionClick}
                  actions={actions}
                  type={type}
                  isBulkSelectedAll={isBulkSelectedAll}
                  SetIsBulkSelectedAll={SetIsBulkSelectedAll}
                  handleSelectAll={handleSelectAll}
                  selectedCves={selectedCves}
                  setSelectedCves={setSelectedCves}
                  checkedState={checkedState}
                  setCheckedState={setCheckedState}
                  handleSelection={handleSelection}
                  isUser={!isViewUser}
                />
                {showModal && (
                  <GenericActionModal
                    showModal={showModal}
                    handleClose={handleModalClose}
                    onConfirm={onConfirm}
                    actionType={modalData?.actionType}
                    groupData={modalData?.data}
                    type={modalData?.type}
                  />
                )}
              </div>
            ) : type === "history" ? (
              <div className="data-processing-screen-main">
                <Folder className="icon mb-2" />
                <h1 className="mb-2 empty-message-title">
                  No Issues Currently in History
                </h1>
                <p className="empty-message-description">
                  Resolved, false-positive or acknowledged issues will appear
                  here
                </p>
              </div>
            ) : isFinished ? (
              <div className="data-processing-screen-main">
                <NoIssue className="icon mb-2" />
                <h1 className="mb-2">Issue-Free Verification</h1>
                <p>At the last scan, no issues were detected.</p>
              </div>
            ) : (
              <div className="data-processing-screen-main">
                <DataProcess className="icon mb-2" />
                <h1 className="mb-2">Data in Process</h1>
                <p>Hang tight! We're currently processing your data.</p>
              </div>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default OpenIssues;
