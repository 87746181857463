import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { ReactComponent as InfoIcon } from "../../assets/images/circle-info.svg";
import "./WebUpdate.scss";
const VerticalUpdates = ({ data, onSort, showTooltip, handleMouseEnter, handleMouseLeave, removedMessages }) => (

    <div className="updates-grid">
      <div className="updates-item">
        <div className="">New</div>
        <div
          className={`updates-value  ${
            data?.new ? "underline cursor-pointer" : ""
          }`}
        >
          <OverlayTrigger
            placement="top"
            delay={{ show: 2000 }}
            overlay={
              <Popover id={`tooltip-New`} className="custom-popover-arrow">
                <Popover.Body className="comming-tool-body">
                  <label className="comming-text-tool">Filter Results</label>
                </Popover.Body>
              </Popover>
            }
          >
            <div onClick={() => data?.new > 0 && onSort("New")}>
              {data?.new || 0}
            </div>
          </OverlayTrigger>
        </div>
      </div>
      <div className="updates-item">
        <div className="">Changed</div>
        <div
          className={`updates-value  ${
            data?.changed ? "underline cursor-pointer" : ""
          }`}
        >
          <OverlayTrigger
            placement="top"
            delay={{ show: 2000 }}
            overlay={
              <Popover id={`tooltip-Changed`} className="custom-popover-arrow">
                <Popover.Body className="comming-tool-body">
                  <label className="comming-text-tool">Filter Results</label>
                </Popover.Body>
              </Popover>
            }
          >
            <div onClick={() => data?.changed > 0 && onSort("Changed")}>
              {data?.changed || 0}
            </div>
          </OverlayTrigger>
        </div>
      </div>
      <div className="updates-item" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div className="flex align-items-center">
        <span>Removed</span>
        {data?.removed !== 0 && (
          <OverlayTrigger
            show={showTooltip}
            placement="top"
            overlay={
              <Popover id={`tooltip-Removed`} className="custom-popover-arrow">
                <Popover.Body className="removed-updates ">
                  {removedMessages.length > 0 ? (
                    <ul style={{padding: "0px 10px"}}>
                      {removedMessages.map((message) => (
                        <li key={message} className="item-text mb-3">
                          {message}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <label className="comming-text-tool">No removed updates available.</label>
                  )}
                </Popover.Body>

              </Popover>
            }
          >
            <div className="cursor-pointer icon-style">
              <InfoIcon />
            </div>
          </OverlayTrigger>
        )}
      </div>
      <div className="updates-value">
        <div>{data?.removed || 0}</div>
      </div>
    </div>
      <div className="updates-item">
        <div className="">Unchanged</div>
        <div
          className={`updates-value  ${
            data?.constant ? "underline cursor-pointer" : ""
          }`}
        >
          <OverlayTrigger
            placement="top"
            delay={{ show: 2000 }}
            overlay={
              <Popover
                id={`tooltip-Unchanged`}
                className="custom-popover-arrow"
              >
                <Popover.Body className="comming-tool-body">
                  <label className="comming-text-tool">Filter Results</label>
                </Popover.Body>
              </Popover>
            }
          >
            <div onClick={() => data?.constant > 0 && onSort("Unchanged")}>
              {data?.constant || 0}
            </div>
          </OverlayTrigger>
        </div>
      </div>
    </div>
);


export default VerticalUpdates;
