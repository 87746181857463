import React, { useState, useEffect, useRef, useMemo, useContext } from "react";
import BaseTable from "../../../../components/table/BaseTable";
import BaseFilter from "../../../../components/filter/BaseFilter";
import WebScanner from "../../../../components/web-scanner/WebScanner";
import UpdatesContainer from "../../../../components/website-updates/UpdatesContainer";
import BarChart from "../../../../components/charts/BarChart";
import GenericCard from "../../../../components/card/GenericCard";
import { ThreeCircles } from "react-loader-spinner";
import DecisionToggle from "../../../../components/badge/DecisionToggle";
import DoughnutChart from "../../../../components/charts/DoughnutChart";
import NoData from "../../../../components/empty/NoData";
import { useNavigate, useParams, useOutletContext } from "react-router-dom";
import axios from "../../../../util/axios";
import TestedPopover from "../../../../components/shared/TestedPopover.js";
import { NoDataAfterScanning } from "../../../../components/empty/NoDataAfterScanning";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { ScannerContext } from "../../../../components/ScannerContext";
import { formatScannerName } from "../../../../helpers/formatScannerName.js";
import moment from "moment";
import CurrentStatus from "../../../../components/status/CurrentStatus.js";
import { webUpdatesFilter } from "../../../../util/genericFunctions.js";
import { getArmoryID } from "../../../../util/ArmoryIdMapping.js";
import { parseFilterString, applyCondition } from "../../../../util/conditions";
import { emailSecurityTabsConfig } from "../../../../util/tabsConfig.js";
import { formatDate } from "../../../../util/formatDate.js";
import { ReactComponent as RedCross } from "../../../../assets/images/Red-Cross.svg";
import { ReactComponent as GrayCross } from "../../../../assets/images/Grey-Cross.svg";
import { spoofingFilter } from "../default-credential/filterOptions.js";
import { barChartOptions } from "../../../../util/chartUtils.js";

const EmailSecurity = () => {
  const { dropdownItems } = useContext(ScannerContext);
  const { scanningStatus } = useOutletContext();
  const routeParams = useParams();
  const [searchValue, setSearchValue] = useState("");
  const [isDatainProcess, setIsDataInProcess] = useState(false);
  const [noDataDoughnutChart, setNoDataDoughtnutChart] = useState(false);
  const [isDataisPartiallyProcessing, setIsDataisPartiallyProcessing] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [webUpdatesData, setWebUpdatesData] = useState(null);
  const [activeFilters, setActiveFilters] = useState([]);
  const navigate = useNavigate();
  const [tabs, setTabs] = useState([]);
  const [noDataBarChart, setNoDataBarChart] = useState(false);
  const [barChartType, setBarChartType] = useState([]);
  const [allEmails, setAllEmails] = useState([]);
  const [chartData, setChartData] = useState({
    labels: ["Secured", "Unsecured"],
    datasets: [
      {
        data: [],
        backgroundColor: ["#3DDC97", "#FF6155"],
        hoverOffset: 4,
        borderColor: "transparent",
        borderWidth: 0,
        borderAlign: "center",
      },
    ],
  });
  const [barChartData, setBarChartData] = useState({
    labels: ["Vulnerable", "Not Vulnerable"],
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: ["#FF6155", "#3DDC97"],
        borderColor: ["rgb(255, 99, 132)", "rgb(75, 192, 192)"],
        borderWidth: 1,
      },
    ],
  });

  const handelClickOnSecurityIssue = (domain, status, row, type,reason) => {    
    const armoryID = getArmoryID(
      type,
     reason
    );
    
    if (!status && armoryID) {
      navigate(
        `/security-issues/${routeParams?.target_id}?domain=${row?.host}&armoryIdParam=${armoryID}`
      );
    }
  };  

  const handelClickOnIssueSpoofing = (row) => {
    if (row?.spoofing) {
      navigate(
        `/security-issues/${routeParams?.target_id}?domain=${row?.host}&armoryIdParam=arm-mx12`
      );
    }
  };

  const CircularChartoptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "80%",
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Secure vs Unsecured Email Configuration",
        color: "white",
        font: {
          family: "Kodchasan",
          size: 24,
        },
      },
    },
    onHover: (event, chartElement) => {
      const targetCanvas = event.native ? event.native.target : event.target;
      if (chartElement.length) {
        targetCanvas.style.cursor = "pointer";
      } else {
        targetCanvas.style.cursor = "default";
      }
    },
  };

  const columns = useMemo(
    () => [
      { Header: "Domain", accessor: "host", isSortable: true },
      {
        Header: "SPF",
        isSortable: true,
        key: "is_spf_secured",
        accessor: (row) => {
          const reasons = row?.is_spf_secured || []; 
          const popover = row.spf_results_raw && (
            <Popover
              id="popover-basic-comming"
              className="custom-popover-arrow"
            >
              <Popover.Body className="email-tool-body scrollable-checkbox-list">
                {row.spf_results_raw.split(/;|\s/).map((item, index) => (
                  <span key={index} className="text-black d-block">
                    {item.trim()}
                  </span>
                ))}
              </Popover.Body>
            </Popover>
          );
      
          return (
            <div>
              {reasons.map((reason) => {
                const isNoSPFRecord = reason === "No SPF Record found";
                return row.isSPFSecured ? (
                  <div
                    key={reason}
                    style={{ display: "inline-block", marginBottom: "8px" }}
                  >
                    <DecisionToggle
                      label={reason}
                      isIndication={false}
                    />
                  </div>
                ) : (
                  <div
                    key={reason}
                    onClick={() => {
                    
                        handelClickOnSecurityIssue(
                          row.host,
                          row.is_spf_secured.includes("Securely Configured"),
                          row,
                          "SPF",
                          reason
                        );
                      
                    }}
                  >
                    {!isNoSPFRecord ? (
                      <OverlayTrigger
                        trigger={["hover", "focus"]}
                        placement="top"
                        overlay={popover}
                      >
                        <div
                          style={{ display: "inline-block", marginBottom: "8px" }}
                        >
                          <DecisionToggle
                            label={reason}
                            isIndication={reason === "Securely Configured"}
                          />
                        </div>
                      </OverlayTrigger>
                    ) : (
                      <div
                        style={{ display: "inline-block", marginBottom: "8px" }}
                      >
                        <DecisionToggle
                          label={reason}
                          isIndication={reason === "Securely Configured"}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          );
        },
      },               
      {
        Header: "DMARC",
        isSortable: true,
        key: "is_dmarc_secured",
        accessor: (row) => {
          const reasons = Array.isArray(row.is_dmarc_secured)
            ? row.is_dmarc_secured.map((reason) =>
                reason === "p=none" ? "Too Lenient Policy" : reason
              )
            : [
                row.is_dmarc_secured === "p=none"
                  ? "Too Lenient Policy"
                  : row.is_dmarc_secured,
              ];
      
          const popover = row.dmarc_results_raw && (
            <Popover
              id="popover-basic-comming"
              className="custom-popover-arrow"
            >
              <Popover.Body className="email-tool-body scrollable-checkbox-list">
                {row.dmarc_results_raw.split(/;|\s/).map((item, index) => (
                  <span key={index} className="text-black d-block">
                    {item.trim()}
                  </span>
                ))}
              </Popover.Body>
            </Popover>
          );
      
          return (
            <div>
              {reasons.map((reason, index) => {
                const isNoDMARCRecord = reason === "No DMARC Record found";
      
                return (
                  <div
                    key={`${reason}-${index}`}
                    style={{
                      display: "inline-block",
                      marginBottom: "8px",
                      cursor: isNoDMARCRecord ? "default" : "pointer",
                    }}
                    onClick={() => {
                   
                        handelClickOnSecurityIssue(
                          row.host,
                          row.is_dmarc_secured.includes("Securely Configured"),
                          row,
                          "DMARC",
                          reason
                        );
                      
                    }}
                  >
                    {!isNoDMARCRecord ? (
                      <OverlayTrigger
                        trigger={["hover", "focus"]}
                        placement="top"
                        overlay={popover}
                      >
                        <div>
                          <DecisionToggle
                            label={reason}
                            isIndication={reason === "Securely Configured"}
                          />
                        </div>
                      </OverlayTrigger>
                    ) : (
                      <div>
                        <DecisionToggle
                          label={reason}
                          isIndication={reason === "Securely Configured"}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          );
        },
      },
               
      {
        Header: <TestedPopover title="Email Spoofing" />,
        key: "spoofing",
        isSortable: true,
        width: "25",
        accessor: (row) => {
          return (
            <div>
              {row?.spoofing ? (
                <div className="d-flex gap-2 custom-ssh-badge">
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      handelClickOnIssueSpoofing(row);
                    }}
                  >
                    <DecisionToggle
                      label={
                        <div className="d-flex align-items-center">
                          <RedCross className="me-1" />
                          <div>Vulnerable</div>
                        </div>
                      }
                      isIndication={false}
                    />
                  </div>
                </div>
              ) : (
                <div className="no-pointer">
                  <DecisionToggle
                    label={
                      <div className="d-flex align-items-center">
                        <GrayCross className="me-1" />
                        <div>Not Vulnerable</div>
                      </div>
                    }
                    isIndication={true}
                  />
                </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "First Detected",
        key: "first_detected",
        isSortable: true,
        accessor: (row) => {
          return (
            <div>
              {row?.first_detected ? formatDate(row?.first_detected) : ""}
            </div>
          );
        },
      },
      {
        Header: "Current State",
        key: "current_state",
        isSortable: true,
        accessor: (row) => {
          return (
            <div>
              {row?.change_status != null ? (
                <CurrentStatus
                  status={row?.change_status}
                  tooltipInfo={row?.changes}
                  headerKeys={{
                    host: "Domain",
                    is_spf_secured: "SPF",
                    is_dmarc_secured: "DMARC",
                    secureStatus: "Secure vs Unsecured",
                    first_detected: "First Detected",
                    spoofing: "Email Spoofing",
                    change_status: "Current State",
                  }}
                />
              ) : (
                ""
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  const [emailConfigurationFilters, setEmailConfigurationFilters] = useState([
    {
      id: 0,
      name: "Secured",
      type: "Secured vs Unsecured: Secured",
      key: "2",
      active: false, // Initially not active
    },
    {
      id: 1,
      name: "Unsecured",
      type: "Secured vs Unsecured: Unsecured",
      key: "2",
      active: false, // Initially not active
    },
  ]);

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleWebUpdateSort = (name) => {
    setActiveFilters(webUpdatesFilter(name, activeFilters));
  };

  const updateTabsWithData = (emails) => {
    const domains = Array.from(
      new Set(emails.map((domain) => domain.host))
    ).map((domain) => ({
      id: domain,
      name: domain,
      type: "Domain: " + domain,
      key: "1",
      active: false,
    }));
    const spfData = Array.from(
      new Set(
        emails.flatMap((domain) =>
          Array.isArray(domain.is_spf_secured)
            ? domain.is_spf_secured.map((reason) =>
                reason.replace(/Using multiple spf Records/gi, "Using Multiple Records")
              )
            : [domain.is_spf_secured.replace(/Using multiple spf Records/gi, "Using Multiple Records")]
        )
      )
    )
      .filter((el) => el)
      .map((spf) => ({
        id: spf,
        name: spf,
        type: "SPF: " + spf,
        key: "3",
        active: false,
      }));    

      const dmarcData = Array.from(
        new Set(
          emails.flatMap((domain) =>
            Array.isArray(domain.is_dmarc_secured)
              ? domain.is_dmarc_secured.map((reason) =>
                  reason.replace(/Too Lenient Policy/gi, "Too Lenient Policy")
                )
              : [domain.is_dmarc_secured]
          )
        )
      )
        .filter((el) => el)
        .map((dmarc) => ({
          id: dmarc,
          name: dmarc,
          type: "DMARC: " + dmarc,
          key: "4",
          active: false,
        }));
      

    setTabs(
      emailSecurityTabsConfig({
        domains,
        spfData,
        dmarcData,
        emailConfigurationFilters,
        spoofingFilter,
      })
    );
  };

  const fetchEmailSecurity = async () => {
    try {
      const { data } = await axios.get(
        `scans/${routeParams.target_id}/email-security`
      );
      setIsLoading(false);
      setWebUpdatesData(data?.groups?.changes);
      let modifiedData = data?.result?.map((el) => {
        let spfMessage = el?.is_spf_secured
        ? ["Securely Configured"]
        : el?.spf_results?.reason?.length > 0
        ? el?.spf_results?.reason
        : ["No SPF Record Found"];
        let dmarcMessage = el?.is_dmarc_secured
  ? ["Securely Configured"]
  : el?.dmarc_results?.reason?.length > 0
  ? el?.dmarc_results?.reason
  : ["No Record Found"];

        const isSecure = el?.is_spf_secured && el?.is_dmarc_secured;
        return {
          ...el,
          is_spf_secured: spfMessage,
          spf_results_raw: el?.spf_results?.raw,
          dmarc_results_raw: el?.dmarc_results?.raw,
          is_dmarc_secured: dmarcMessage,
          status: el?.spf_results?.status,
          secureStatus: isSecure ? "Secured" : "Unsecured",
          change_status: el?.current_status,
          changes: el?.changes,
          spoofing: el?.spoofing,
          first_detected: el?.first_detected,
        };
      });
      setAllEmails(modifiedData);
      setBarChartData({
        labels: ["Vulnerable", "Not Vulnerable"],
        datasets: [
          {
            label: ["Vulnerable", "Not Vulnerable"],
            data: [
              data?.groups?.emailSpoofing?.vulnerable || 0,
              data?.groups?.emailSpoofing?.notVulnerable || 0,
            ],
            backgroundColor: ["#FF6155", "#3DDC97"],
            borderColor: ["rgb(255, 99, 132)", "rgb(75, 192, 192)"],
            borderWidth: 1,
            maxBarThickness: 70,
            borderRadius: {
              topLeft: 5,
              topRight: 5,
            },
          },
        ],
      });
      setChartData({
        labels: ["Secured", "Unsecured"],
        datasets: [
          {
            data: [data.groups?.secure?.yes, data.groups?.secure?.no],
            backgroundColor: ["#3DDC97", "#FF6155"],
            hoverOffset: 4,
            borderColor: "transparent",
            borderWidth: 0,
            borderAlign: "center",
          },
        ],
      });
      if (
        scanningStatus === -1 ||
        scanningStatus === 3 ||
        scanningStatus === 4 ||
        scanningStatus === 1 ||
        scanningStatus === 2
      ) {
        if (data.groups?.secure?.yes || data.groups?.secure?.no) {
          setNoDataDoughtnutChart(false);
        } else {
          setNoDataDoughtnutChart(true);
        }
        if (
          data?.groups?.emailSpoofing?.vulnerable ||
          data?.groups?.emailSpoofing?.notVulnerable
        ) {
          setNoDataBarChart(false);
        } else {
          setNoDataBarChart(true);
        }
      }
      if (modifiedData?.length > 0) {
        updateTabsWithData(modifiedData);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  const fetchIntervalRef = useRef();
  useEffect(() => {
    clearInterval(fetchIntervalRef.current);

    if (scanningStatus === 0) {
      setIsLoading(false);
      setIsDataInProcess(true);
    } else {
      setIsDataInProcess(false);
      if (
        scanningStatus === 3 ||
        scanningStatus === -1 ||
        scanningStatus === 4
      ) {
        setIsDataisPartiallyProcessing(false);
        setIsLoading(true);
        fetchEmailSecurity();
      } else if (scanningStatus === 2 || scanningStatus === 1) {
        setIsDataisPartiallyProcessing(true);
        setIsLoading(true);
        fetchEmailSecurity();
        fetchIntervalRef.current = setInterval(fetchEmailSecurity, 5000);
      }
    }
    return () => {
      clearInterval(fetchIntervalRef.current);
    };
  }, [scanningStatus, routeParams?.target_id]);

  const handleFilterChange = (updatedActiveFilters, updatedTabs) => {
    setActiveFilters(updatedActiveFilters);
    setTabs(updatedTabs);
  };

  const removeFilter = (updatedFilters, updatedTabs) => {
    setActiveFilters(updatedFilters);
    setTabs(updatedTabs);
  };

  const statusMap = { new: 1, changed: 2, unchanged: 0 };
  const toggleFilter = (name, filters) => {
    return filters.map((filter) => {
      if (filter.name === name) {
        return { ...filter, active: !filter.active }; // Toggle active state
      }
      return filter;
    });
  };

  const handleChartClick = (
    name,
    eventKey = "2",
    filterType = "Secured vs Unsecured"
  ) => {
    // Generic toggle filter logic
    const updatedFilters = toggleFilter(name, emailConfigurationFilters);

    setEmailConfigurationFilters(updatedFilters);

    setActiveFilters((prevActiveFilters) => {
      // Check if the filter is already active using the eventKey and filter name
      const isFilterAlreadyActive = prevActiveFilters.some(
        (filter) => filter.name === name && filter.eventKey === eventKey
      );

      if (isFilterAlreadyActive) {
        // Remove the active filter
        return prevActiveFilters.filter((filter) => filter.name !== name);
      } else {
        // Add a new filter based on eventKey and filterType
        return webUpdatesFilter(name, prevActiveFilters, eventKey, filterType);
      }
    });

    // Update the relevant tab dynamically
    setTabs((prevTabs) =>
      prevTabs.map((tab) => {
        if (tab.eventKey === eventKey) {
          return {
            ...tab,
            data: updatedFilters,
          };
        }
        return tab;
      })
    );
  };

  const handleBarChartClick = (name) => {
    const updatedFilters = webUpdatesFilter(
      name,
      activeFilters,
      "5",
      "Email Spoofing"
    );

    const updatedCertificateExpiration = tabs[4].data.map((item) => ({
      ...item,
      active: updatedFilters.some((filter) => filter.name === item.name),
    }));

    setTabs((prevTabs) => {
      const newTabs = [...prevTabs];
      newTabs[4] = {
        ...newTabs[4],
        data: updatedCertificateExpiration,
      };
      return newTabs;
    });
    setActiveFilters(updatedFilters);
  };

  const matchesFiltersAndSearch = (
    item,
    activeFilters,
    searchValue,
    statusMap
  ) => {
    const matchesFilters =
      activeFilters.length === 0 ||
      activeFilters.some((filter) => {
        if (filter.eventKey === "1") {
          return item.host?.toLowerCase() === filter.name.toLowerCase();
        } else if (filter.eventKey === "6") {
          return item?.change_status === statusMap[filter.name.toLowerCase()];
        } else if (filter.eventKey === "5") {
          const isVulnerable = item?.spoofing;
          if (filter.name?.toLowerCase() === "not vulnerable") {
            return !isVulnerable;
          } else if (filter.name?.toLowerCase() === "vulnerable") {
            return isVulnerable;
          }
        } else if (
          filter.eventKey === "2" &&
          filter.type.startsWith("Secured vs Unsecured")
        ) {
          const isSPFSecured = item.is_spf_secured === "Securely Configured";
          const isDMARCSecured =
            item.is_dmarc_secured === "Securely Configured";
          const isStatusSecure = isSPFSecured && isDMARCSecured;
          if (filter.name === "Secured") {
            return isStatusSecure;
          }
          if (filter.name === "Unsecured") {
            return !isStatusSecure;
          }
        } else if (filter.eventKey === "3") {
          return Array.isArray(item.is_spf_secured)
          ? item.is_spf_secured.some((reason) =>
              reason
                .replace(/Using multiple spf Records/gi, "Using Multiple Records")
                .toLowerCase() ===
              filter.name
                .replace(/Using multiple spf Records/gi, "Using Multiple Records")
                .toLowerCase()
            )
          : item.is_spf_secured
              ?.replace(/Using multiple spf Records/gi, "Using Multiple Records")
              ?.toLowerCase() ===
            filter.name
              ?.replace(/Using multiple spf Records/gi, "Using Multiple Records")
              ?.toLowerCase();
        } else if (filter.eventKey === "4") {
          const dmarcValues = Array.isArray(item.is_dmarc_secured)
            ? item.is_dmarc_secured.map((reason) =>
                reason === "p=none" ? "Too Lenient Policy" : reason
              )
            : [
                item.is_dmarc_secured === "p=none"
                  ? "Too Lenient Policy"
                  : item.is_dmarc_secured,
              ];
        
          return dmarcValues.some(
            (dmarcValue) => dmarcValue?.toLowerCase() === filter.name?.toLowerCase()
          );
        }
        else if (filter.eventKey === "advanced-filter") {
          const parsedFilters = parseFilterString(filter.name);
          return parsedFilters.every((ol) => {
            const { column, condition, value } = ol;
            switch (column) {
              case "host":
                return applyCondition(
                  item.host?.toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
                case "is_spf_secured":
                  return Array.isArray(item.is_spf_secured)
                    ? item.is_spf_secured.some((reason) =>
                        applyCondition(
                          reason
                            ?.replace(/Using multiple spf Records/gi, "Using Multiple Records")
                            ?.toLowerCase(),
                          condition,
                          value
                            ?.replace(/Using multiple spf Records/gi, "Using Multiple Records")
                            ?.toLowerCase()
                        )
                      )
                    : applyCondition(
                        item.is_spf_secured
                          ?.replace(/Using multiple spf Records/gi, "Using Multiple Records")
                          ?.toLowerCase(),
                        condition,
                        value
                          ?.replace(/Using multiple spf Records/gi, "Using Multiple Records")
                          ?.toLowerCase()
                      );  
              case "spoofing":
                const isVulnerable = item?.spoofing;
                if (value?.toLowerCase() === "not vulnerable") {
                  return condition == "is" ? !isVulnerable : isVulnerable;
                } else if (value?.toLowerCase() === "vulnerable") {
                  return condition == "is" ? isVulnerable : !isVulnerable;
                }
                case "is_dmarc_secured":
                  return Array.isArray(item.is_dmarc_secured)
                    ? item.is_dmarc_secured.some((reason) =>
                        applyCondition(
                          reason?.toLowerCase(),
                          condition,
                          value?.toLowerCase()
                        )
                      )
                    : applyCondition(
                        item.is_dmarc_secured?.toLowerCase(),
                        condition,
                        value?.toLowerCase()
                      );                
              case "is_secured":
                const isSPFSecured =
                  item.is_spf_secured === "Securely Configured";
                const isDMARCSecured =
                  item.is_dmarc_secured === "Securely Configured";
                const isStatusSecure = isSPFSecured && isDMARCSecured;
                if (value?.toLowerCase() === "secured") {
                  return isStatusSecure;
                }
                if (value?.toLowerCase() === "unsecured") {
                  return !isStatusSecure;
                }
              case "current_state":
                return applyCondition(
                  item?.change_status,
                  condition,
                  statusMap[value.toLowerCase()]
                );

              default:
                return false;
            }
          });
        }
        return false;
      });

    // Check if item matches the search value
    const matchesSearch =
      searchValue === "" || // If searchValue is empty, match all
      item.host?.toLowerCase().includes(searchValue.toLowerCase()); // Search by domain name

    return matchesFilters && matchesSearch; // Return only if both conditions are met
  };
  const filteredEmails = useMemo(() => {
    return allEmails.filter((item) =>
      matchesFiltersAndSearch(item, activeFilters, searchValue, statusMap)
    );
  }, [allEmails, activeFilters, searchValue, statusMap]);

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="content-loader">
          <ThreeCircles
            visible={true}
            height="60"
            width="60"
            color="#ffff"
            ariaLabel="three-circles-loading"
            wrapperClass=""
          />
        </div>
      ) : (
        <div className="main_container_style">
          <div className="pb-4 row top-container-main web-updates-container">
            <div className="col-12 col-lg-6 h-100 web-updates-col-7">
              <div className="row gap-4 top-container-main-row">
                <div className="col-12 h-50">
                  <GenericCard
                    children={
                      isDatainProcess ? (
                        <NoData />
                      ) : isDataisPartiallyProcessing && noDataDoughnutChart ? (
                        <NoData />
                      ) : noDataDoughnutChart ? (
                        <NoDataAfterScanning />
                      ) : isDatainProcess ? (
                        <NoData />
                      ) : (
                        <DoughnutChart
                          data={chartData}
                          options={CircularChartoptions}
                          onHandleClick={handleChartClick}
                        />
                      )
                    }
                    title={"Email Configuration"}
                    isCentered={false}
                  />
                </div>
                <div className="col-12 h-50">
                  <div className="row h-100">
                    <div className="col-5">
                      <GenericCard
                        children={
                          <UpdatesContainer
                            data={webUpdatesData}
                            onSort={handleWebUpdateSort}
                            dataInProcess={
                              isDatainProcess || isDataisPartiallyProcessing
                            }
                          />
                        }
                        title={"Updates"}
                        subtitle={"View Issues"}
                      />
                    </div>
                    <div className="col-7">
                      <GenericCard
                        children={
                          isDatainProcess ? (
                            <NoData />
                          ) : isDataisPartiallyProcessing && noDataBarChart ? (
                            <NoData />
                          ) : noDataBarChart ? (
                            <NoDataAfterScanning />
                          ) : (
                            <BarChart
                              barData={barChartData}
                              optionsData={barChartOptions}
                              onBarClick={handleBarChartClick}
                              isData={
                                !!(
                                  barChartType &&
                                  barChartType.no !== undefined &&
                                  barChartType.yes !== undefined
                                )
                              }
                              dynamicLabels={["Vulnerable", "Not Vulnerable"]}
                              isChartLabelsOnTop
                            />
                          )
                        }
                        title={"Email Spoofing"}
                        subtitle={"View Issues"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 h-100 web-updates-col-5">
              <WebScanner title={"Email Security Scanner"} />
            </div>
          </div>

          <div className="pb-5">
            <div>
              <BaseFilter
                tabs={tabs}
                className="mt-3"
                onFilterChange={handleFilterChange}
                activeFilters={activeFilters}
                removeFilter={removeFilter}
                totalRecords={allEmails.length}
                exportTitle={`EmailSecurity_${formatScannerName(
                  dropdownItems.filter((item) => {
                    return item.target_uuid === routeParams?.target_id;
                  })[0]?.title
                )}-${moment().format("DDMMMYYYY").toUpperCase()}`}
                exportHeader={["Domain", "SPF", "DMARC"]}
                exportRows={filteredEmails.map((email) => {
                  const isSPFSecured =
                    Array.isArray(email.is_spf_secured) &&
                    email.is_spf_secured.includes("Securely Configured");
                  const spfStatus = Array.isArray(email.is_spf_secured)
                    ? email.is_spf_secured.join("; ")
                    : email.is_spf_secured;
                  const dmarcStatus = Array.isArray(email.is_dmarc_secured)
                    ? email.is_dmarc_secured.join("; ")
                    : email.is_dmarc_secured;
                  const isDMARCSecured =
                    email.is_dmarc_secured === "Securely Configured";
                  const isStatusSecure = isSPFSecured && isDMARCSecured;
                  return [email.host, spfStatus, dmarcStatus];
                })}                
                isDatainProcess={isDatainProcess || isDataisPartiallyProcessing}
                searchValue={searchValue}
                onSearchChange={handleSearchChange}
                isSearch={true}
                tableData={filteredEmails}
              />
            </div>
            <div>
              <BaseTable
                className="mt-3 mb-3"
                columns={columns}
                data={filteredEmails}
                selectable={true}
                showCheckboxes={false}
                action={false}
                isDatainProcess={isDatainProcess || isDataisPartiallyProcessing}
                loading={isLoading}
                isStickyScroll
              />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default EmailSecurity;
