import React, { useState } from "react";
import { generateRemovedMessages } from "../../util/UpdatesRemove";
import HorizontalUpdates from "./HorizontalUpdates";
import VerticalUpdates from "./VerticalUpdates";
import NoUpdate from "../empty/NoUpdate";
import NoData from "../empty/NoData";

const UpdatesContainer = ({ data, onSort, dataInProcess, updatesRemove = {}, horizontal = false }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const removedMessages = generateRemovedMessages(updatesRemove);

  const handleMouseEnter = () => setShowTooltip(true);
  const handleMouseLeave = () => setShowTooltip(false);

  if (
    !data ||
    !(data.constant > 0 || data.changed > 0 || data.removed > 0 || data.new > 0)
  ) {
    return (
      <div className="update-card">
        <div className="d-flex flex-column justify-content-center py-1 px-1 gap-2 h-100">
          {dataInProcess ? <NoData /> : <NoUpdate />}
        </div>
      </div>
    );
  }

  const commonProps = {
    data,
    onSort,
    showTooltip,
    handleMouseEnter,
    handleMouseLeave,
    removedMessages,
  };

  return horizontal ? (
  
    <HorizontalUpdates {...commonProps} />

  
  ) : (
 
    <VerticalUpdates {...commonProps} />
   
  );
};

export default UpdatesContainer;
